import React, { useContext, useMemo, useState } from "react";
import { AuthContext } from "../../contexts/UserContext";
import Select from "react-select";
import countryList from "react-select-country-list";
import axios from "axios";
import ToastSuccess from "../Utilities/ToastSuccess";
import { Plans, SigninProvider, TargetExams } from "../Utilities/Enums";
import { useEffect } from "react";
import Spinner from "../Utilities/Loader";

const Profile = () => {
  const [success, setSuccess] = useState(false);
  const { user, userProfile, setUserProfile } = useContext(AuthContext);
  const [editedUserProfile, setEditedUserProfile] = useState({
    ...userProfile,
    TargetScore: {
      Reading: "",
      Listening: "",
      Speaking: "",
      Writing: "",
    },
  });
  const countries = useMemo(() => countryList().getData(), []);
  const TargetScore = {
    Reading: editedUserProfile?.TargetScore?.Reading || "", // Provide default value
    Listening: editedUserProfile?.TargetScore?.Listening || "", // Provide default value
    Speaking: editedUserProfile?.TargetScore?.Speaking || "", // Provide default value
    Writing: editedUserProfile?.TargetScore?.Writing || "", // Provide default value
  };

  useEffect(() => {
    setEditedUserProfile(userProfile);
    console.log(editedUserProfile);
  }, [userProfile]);

  const handleCancel = () => {
    setEditedUserProfile({ ...userProfile });
  };

  const handleSave = () => {
    const isNewUser = localStorage.getItem("isNewUser");

    if (isNewUser === "true") {
      // Handle new user creation logic here

      //NOT really going to spend a lot of time trying to figure out a solution now, something for the future
      //check if the user is created in the last 24 hours or so, then try to create the user
      //TODO Can remove all of these as new user is being created in the context when we get a 404 after initial profile load
      const userCreated = new Date(user?.metadata.creationTime);
      const now = new Date();
      const differenceInHours = (now - userCreated) / (1000 * 60 * 60);
      if (differenceInHours > 24) {
        console.log("user creating");
      }
      //TODO setup an observable onAuthStateChanged
      localStorage.setItem("isNewUser", false);
    } else {
      updateUserInMongo();
    }

    console.log("Saving changes:", editedUserProfile);
  };

  const updateUserInMongo = () => {
    const API_URL = `${process.env.REACT_APP_API_URL}/v1/api/users/${editedUserProfile.Id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        "Content-Type": "application/json",
      },
    };

    setSuccess(false);

    axios
      .put(API_URL, getRequestBody(), config)
      .then((response) => {
        setUserProfile(editedUserProfile);
        setSuccess(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getRequestBody = () => {
    const { uid, email, displayName } = user;
    const {
      Id,
      SelectedTest,
      Country,
      Education,
      Destination,
      Plan,
      TargetScore,
    } = editedUserProfile;

    return {
      UserId: uid,
      Id,
      Email: email,
      FullName: displayName,
      SelectedTest,
      CreatedDt: editedUserProfile.CreatedDt,
      PurchasedDt: editedUserProfile.PurchasedDt,
      Provider: SigninProvider.Google,
      emailVerified: user.emailVerified,
      Plan,
      Country,
      Education,
      Destination,
      TargetScore,
    };
  };

  const education = [
    { value: "PE", label: "Primary education" },
    { value: "SE", label: "Secondary education or high school" },
    { value: "VQ", label: "Vocational qualification" },
    { value: "BD", label: "Bachelors degree" },
    { value: "MD", label: "Masters  degree" },
    { value: "DH", label: "Doctorate or higher" },
    { value: "OT", label: "Other" },
  ];

  const isPlanExpired = useMemo(() => {
    const currentDateTime = new Date();
    const planExpiryDate = new Date(editedUserProfile?.Plan?.expiryDate);
    return currentDateTime > planExpiryDate;
  }, [editedUserProfile?.Plan?.expiryDate]);

  return (
    <>
      <ToastSuccess message="Changes saved successfully." show={success} />
      {
        user && editedUserProfile ? (
          <div className="max-w-8xl mx-auto p-6 rounded-md shadow-md text-sm">
            <h2 className="text-lg md:text-xl font-semibold mb-4">
              Edit Profile
            </h2>
            <div className="mb-4">
              <label className="text-gray-600 block mb-2">Name:</label>
              <input
                readOnly
                type="text"
                value={user?.displayName}
                className="w-1/2 p-2 border rounded-md bg-gray-100"
              />
            </div>
            <div className="mb-4">
              <label className="text-gray-600 block mb-2">Email:</label>
              <label className="p-2 border rounded-md bg-gray-100">
                {" "}
                {user?.email}{" "}
              </label>
            </div>

            <div className="mb-4">
              <label className="text-gray-600 block mb-2">Target Exam:</label>
              <Select
                isDisabled={!!userProfile?.IsSubscribed}
                options={TargetExams}
                value={TargetExams.find(
                  (e) => e.value === editedUserProfile?.SelectedTest ?? 2
                )}
                onChange={(selectedOption) => {
                  setEditedUserProfile({
                    ...editedUserProfile,
                    SelectedTest: selectedOption.value,
                  });
                }}
                className="w-1/2"
              />
            </div>
            {editedUserProfile?.Plan && (
              <>
                <div className="mb-4">
                  <label className="text-gray-600 block mb-2">Plan:</label>
                  <div className="flex items-center">
                    <input
                      readOnly
                      type="text"
                      value={
                        Plans.find(
                          (plan) => plan.value == editedUserProfile?.Plan
                        )?.label || ""
                      }
                      className="w-1/2 p-2 border rounded-md bg-gray-100"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  {isPlanExpired ? (
                    <span className="text-red-600 ml-4">
                      Plan Expired: {editedUserProfile?.Plan?.expiryDate}
                    </span>
                  ) : (
                    <span>
                      Plan Expiry Date: {editedUserProfile?.ExpiryDate}
                    </span>
                  )}
                </div>
              </>
            )}

            <div className="mb-4">
              <label className="text-gray-600 block mb-2">Country:</label>
              <Select
                options={countries}
                value={
                  countries.find(
                    (c) => c.value === editedUserProfile?.Country
                  ) || ""
                }
                onChange={(selectedOption) => {
                  setEditedUserProfile({
                    ...editedUserProfile,
                    Country: selectedOption.value,
                  });
                }}
                className="w-1/2"
              />
            </div>

            <div className="mb-4">
              <label className="text-gray-600 block mb-2">Education:</label>
              <Select
                options={education}
                value={education.find(
                  (e) => e.value == (editedUserProfile?.Education || "BD")
                )}
                onChange={(selectedOption) =>
                  setEditedUserProfile({
                    ...editedUserProfile,
                    Education: selectedOption.value,
                  })
                }
                className="w-1/2 p-2 "
              />
            </div>

            <div className="mb-4">
              <label className="text-gray-600 block mb-2">Desired Score:</label>
              <div>
                <label className="text-gray-600 mb-2 inline">
                  Reading:&nbsp;
                </label>
                <input
                  type="text"
                  value={TargetScore.Reading}
                  onChange={(e) =>
                    setEditedUserProfile({
                      ...editedUserProfile,
                      TargetScore: { ...TargetScore, Reading: e.target.value },
                    })
                  }
                  className="w-1/5 p-2 border rounded-md  mx-2"
                />
                <label className="text-gray-600 mb-2 inline ms-2">
                  Writing:&nbsp; &nbsp;
                </label>
                <input
                  type="text"
                  value={TargetScore.Writing}
                  onChange={(e) =>
                    setEditedUserProfile({
                      ...editedUserProfile,
                      TargetScore: { ...TargetScore, Writing: e.target.value },
                    })
                  }
                  className="w-1/5 p-2 border rounded-md  mx-2"
                />
              </div>
              <div className="mt-2">
                <label className="text-gray-600 mb-2 inline">Listening:</label>
                <input
                  type="text"
                  value={TargetScore.Listening}
                  onChange={(e) =>
                    setEditedUserProfile({
                      ...editedUserProfile,
                      TargetScore: {
                        ...TargetScore,
                        Listening: e.target.value,
                      },
                    })
                  }
                  className="w-1/5 p-2 border rounded-md  mx-2"
                />
                <label className="text-gray-600 mb-2 inline ms-2">
                  Speaking:
                </label>
                <input
                  type="text"
                  value={TargetScore.Speaking}
                  onChange={(e) =>
                    setEditedUserProfile({
                      ...editedUserProfile,
                      TargetScore: { ...TargetScore, Speaking: e.target.value },
                    })
                  }
                  className="w-1/5 p-2 border rounded-md  mx-2"
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="text-gray-600 block mb-2">
                Destination Country:
              </label>
              <Select
                options={countries}
                value={
                  countries.find(
                    (c) => c.value === editedUserProfile.Destination
                  ) || "AU"
                }
                onChange={(selectedOption) => {
                  setEditedUserProfile({
                    ...editedUserProfile,
                    Destination: selectedOption.value,
                  });
                }}
                className="w-1/2"
              />
            </div>

            <div className="flex justify-end">
              <button
                className="bg-brightRed text-white py-2 px-4 rounded-md mr-2 hover:bg-brightRedLight"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-400"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <Spinner />
        ) // Return loader if userProfile is not available yet
      }
    </>
  );
};

export default Profile;
