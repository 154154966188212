import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./../../img/logo-white.svg";

const ExamOptions = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    localStorage.setItem("selectedExam", option);
  };

  const examOptions = [
    "PTE",
    "TOEFL",
    "IELTS General",
    "IELTS Academic",
    "OET(Nurses)",
    "OET(Doctors)",
  ];

  const isOptionSelected = selectedOption !== null;

  return (
    <div className="container mx-auto mt-8 p-4">
      <div className="flex justify-center items-center mb-4">
        <img src={logo} alt="Logo" className="w-16 h-16" />
        <span className="ml-2 text-lg font-bold text-gray-800">
          iPASS<i>mocks</i>
        </span>
      </div>
      <h1 className="text-3xl text-center font-bold mb-6">
        Choose an Exam Package:
      </h1>
      <div className="flex justify-center flex-wrap gap-4">
        {examOptions.map((option) => (
          <div
            key={option}
            className={`border p-4 rounded-md shadow-md cursor-pointer ${
              selectedOption === option
                ? "bg-blue-300" // Highlight selected item
                : "hover:bg-gray-100" // Highlight on hover
            }`}
            onClick={() => handleOptionSelect(option)}
          >
            <h2 className="text-lg font-semibold mb-2">{option}</h2>
            {/* Add any additional content or actions for each option */}
          </div>
        ))}
      </div>

      {/* Choose One of the Options */}
      <div className="mt-8">
        <p className="text-lg font-semibold text-center">
          {isOptionSelected
            ? `Selected Option: ${selectedOption}`
            : "Please choose an option before proceeding."}
        </p>
      </div>

      {/* Buttons to move to Sign Up and Blogs pages */}
      <div className="flex justify-center mt-8">
        <Link
          to="/"
          className="bg-brightRed text-white hover:bg-brightRedLight py-2 px-4 rounded-md  mr-4"
        >
          Home
        </Link>
        <Link
          to={isOptionSelected ? "/signup" : "/"}
          className={`bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600mr-4 ${
            isOptionSelected ? "" : "opacity-50 cursor-not-allowed"
          }`}
        >
          Sign Up
        </Link>
      </div>

      {/* Link to Login page */}
      <div className="mt-4 text-center">
        <Link
          to="/login"
          className="text-blue-400 hover:text-brightRed hover:underline"
        >
          Already have an account? Login here.
        </Link>
      </div>
    </div>
  );
};

export default ExamOptions;
