import React from "react";

const IELTSHero = () => {
  return (
    <section
      id="hero"
      className="container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row"
    >
      <div className="flex flex-col mb-8 md:mb-32 space-y-12 md:w-1/2">
        <h1 className="max-w-md text-4xl font-bold text-center md:text-4xl md:text-left">
          Embark on a journey to IELTS success with our Mock Tests and Expert
          Feedbacks!
        </h1>
        <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
          Tailored for both IELTS Academic and IELTS General, these tests offer
          a personalized experience. Receive expert evaluations, detailed
          feedback, and revised answers, turning your IELTS preparation into a
          transformative and successful endeavor! Elevate your performance and
          conquer the IELTS with confidence!
        </p>
        <div className="flex justify-center md:justify-start">
          <a
            href="/signup"
            className="p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight"
          >
            Get Started
          </a>
        </div>
      </div>
      <div className="md:w-1/2">
        <img
          src="https://ucarecdn.com/9cb98ffb-0f5d-4a25-8343-f5f39d9792f9/-/scale_crop/800x800/"
          alt="iPASSmocks success"
          style={{ width: "100%", maxHeight: "700px", objectFit: "cover" }}
        />
      </div>
    </section>
  );
};

export default IELTSHero;
