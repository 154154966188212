import React from "react";

const OETReferral = ({ taskData }) => {
  const { scenario, taskDescription, instructions, note } = taskData;

  return (
    <div className="max-w-2xl mx-auto my-8 p-8 bg-gray-100 rounded shadow">
      <div className="mb-6">
        <strong className="block mb-2">Scenario:</strong>
        <p className="text-gray-700">{scenario}</p>
      </div>

      <div className="mb-6">
        <strong className="block mb-2">Task:</strong>
        <p className="text-gray-700">{taskDescription}</p>
      </div>

      <div className="mb-6">
        <strong className="block mb-2">Instructions:</strong>
        <ul className="list-disc list-inside text-gray-700">
          {instructions.map((instruction, index) => (
            <li key={index}>{instruction}</li>
          ))}
        </ul>
      </div>

      <p className="text-gray-700">
        <strong>Note:</strong> {note}
      </p>
    </div>
  );
};
export default OETReferral;
