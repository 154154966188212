import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/Hero/Hero";
import Features from "../../components/Features/Features";
import Testimonials from "../../components/Testimonials/Testimonials";
import CTA from "../../components/CTA/CTA";
import FAQ from "../../components/FAQ/FAQ";
import Cookie from "../../components/Privacy/Cookie";
import PricingHome from "../../components/Pricing/PricingHome";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const Home = () => {
  const consentGiven = localStorage.getItem("cookieConsent") === "true";

  const location = useLocation();

  // Generate the canonical URL based on the current route
  const canonicalUrl = `${window.location.origin}${location.pathname}`;

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Navbar />
      {!consentGiven && <Cookie />}
      <Hero />
      <Features />
      <Testimonials />
      <CTA />
      <PricingHome />
      <FAQ />
      <Footer />
    </>
  );
};

export default Home;
