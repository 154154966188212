import React, { useState } from "react";
import logo from "./../../img/logo-white.svg";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [NavBarClicked, SetNavBarClicked] = useState(true);

  const toggle = () => {
    SetNavBarClicked(!NavBarClicked);
  };

  return (
    <>
      {/* Navbar */}
      <nav className="relative container mx-auto p-4 bg-gray-100">
        {/* Flex container */}
        <div className="flex items-center justify-between">
          {/* Logo */}
          <div className="pt-2">
            <Link to="/">
              <div className="flex items-center cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105">
                <img className="h-12" src={logo} alt="Logo" />
                <div className="ml-2">
                  <span className="text-lg font-bold text-brightRed">
                    iPASS
                  </span>
                  <span className="text-lg font-bold text-black italic">
                    mocks
                  </span>
                </div>
              </div>
            </Link>
          </div>

          {/* Menu Items - Hidden on Mobile */}
          <div className="hidden space-x-6 md:flex">
            <a href="/ielts" className="text-lg font-bold hover:text-brightRed">
              IELTS
            </a>
            <a href="/oet" className="text-lg font-bold hover:text-brightRed">
              OET
            </a>
            <a href="/pte" className="text-lg font-bold hover:text-brightRed">
              PTE
            </a>
            <a
              href="#pricing"
              className="text-lg font-bold hover:text-brightRed"
            >
              Pricing
            </a>
            {/* Login Button - Hidden on Mobile */}
            <a
              href="/login"
              className="hidden p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight md:block"
            >
              Login
            </a>
          </div>

          {/* Get Started Button - Hidden on Mobile */}
          <a
            href="/signup"
            className="hidden p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight md:block"
          >
            Get Started
          </a>

          {/* Hamburger Icon */}
          <button
            id="menu-btn"
            className={
              NavBarClicked
                ? "block hamburger md:hidden focus:outline-none"
                : "block hamburger md:hidden focus:outline-none open"
            }
            onClick={toggle}
          >
            <span className="hamburger-top"></span>
            <span className="hamburger-middle"></span>
            <span className="hamburger-bottom"></span>
          </button>
        </div>

        {/* Mobile Menu */}
        <div className="md:hidden">
          <div
            id="menu"
            className={
              NavBarClicked
                ? "absolute flex-col items-center hidden self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md"
                : "absolute flex-col items-center self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md flex"
            }
          >
            <a href="/ielts">IELTS</a>
            <a href="/oet">OET</a>
            <a href="/pte">PTE</a>
            <a href="#pricing">Pricing</a>
            <a href="/about">About Us</a>

            {/* Login Button - Visible on Mobile */}
            <a
              href="/login"
              className="p-3 px-6 pt-1 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight"
            >
              Login
            </a>

            {/* Signup Button - Visible on Mobile */}
            <a
              href="/signup"
              className="p-3 px-6 pt-1 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight"
            >
              Signup
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
