import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import CTA from "../../components/CTA/CTA";
import Cookie from "../../components/Privacy/Cookie";
import About from "../../components/About/About";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const AboutHome = () => {
  const consentGiven = localStorage.getItem("cookieConsent") === "true";

  const location = useLocation();

  // Generate the canonical URL based on the current route
  const canonicalUrl = `${window.location.origin}${location.pathname}`;

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Navbar />
      <About />
      {!consentGiven && <Cookie />}
      <CTA />
      <Footer />
    </div>
  );
};

export default AboutHome;
