import React from "react";
import FAQ from "./FAQ";

const OETFAQ = () => {
  const FAQs = [
    {
      question: "What is the purpose of OET writing mock tests?",
      answer:
        "OET writing mock tests simulate real exam conditions, allowing you to practice and assess your writing skills for the OET exam, tailored for medical professionals including nurses, doctors, dentists, pharmacists, and others.",
      open: false,
    },
    {
      question: "How do I access the mock tests on your platform?",
      answer:
        "Upon registration, you'll gain access to our platform, where you can find a variety of OET writing mock tests designed for medical professionals to practice. The number of mock tests available depends on the plan you choose during registration.",
      open: false,
    },
    {
      question:
        "Have the practice tests been updated to align with the new OET test format (OET 2.0)?",
      answer:
        "Absolutely. Our practice tests have been revised to align with the updated OET 2.0 version.",
      open: false,
    },
    {
      question:
        "Are the mock tests specifically designed for various medical professions?",
      answer:
        "Yes, we offer separate sets of mock tests tailored for different medical professions, including nurses, doctors, dentists, pharmacists, and others. During the purchase, you can select the profession-specific test type.",
      open: false,
    },
    {
      question: "What feedback will I receive after taking a mock test?",
      answer:
        "Our expert evaluators provide personalized feedback, highlighting your strengths and areas for improvement in terms of medical terminology, communication, and coherence, along with a revised version of your answer.",
      open: false,
    },
    {
      question: "Is there a limit to the number of mock tests I can take?",
      answer:
        "Yes, there is a limit. The number of mock tests available depends on the plan you choose during registration.",
      open: false,
    },
    {
      question: "Can I access the mock tests from any device?",
      answer:
        "Yes, our platform is accessible from various devices, including laptops, tablets, and smartphones, ensuring flexibility in your study routine. While attempting the tests, we recommend using a laptop or desktop to mimic the real scenario.",
      open: false,
    },
    {
      question:
        "How long will I have access to the mock tests and evaluations?",
      answer:
        "The standard access duration is 3 months. If you need longer access, please contact us, and we'll assist you accordingly.",
      open: false,
    },
    {
      question: "Do you provide revised answers for the mock test questions?",
      answer:
        "Yes, in addition to feedback, we offer revised answers, allowing you to learn from mistakes and refine your writing skills specific to your medical profession.",
      open: false,
    },
    {
      question: "How can I contact support if I have further questions?",
      answer:
        "If you have any additional questions or need support, please feel free to email us at info@ipassmocks.com, and our team will respond promptly.",
      open: false,
    },
  ];
  return <FAQ customFAQs={FAQs}></FAQ>;
};

export default OETFAQ;
