import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/UserContext";

const PaymentsResponse = (props) => {
  const { stripePromise } = props;
  const [success, setSucess] = useState(true);
  const { user, userProfile, setUserProfile } = useContext(AuthContext);

  useEffect(() => {
    if (!stripePromise) return;

    stripePromise.then(async (stripe) => {
      const url = new URL(window.location);
      const clientSecret = url.searchParams.get("payment_intent_client_secret");
      const { error, paymentIntent } = await stripe.retrievePaymentIntent(
        clientSecret
      );
      if (paymentIntent?.status === "succeeded") {
        console.log("update user profile");
      }
      if (error) setSucess(false);
    });
  }, [stripePromise]);

  useEffect(() => {
    if (success && userProfile) {
      if (userProfile?.IsSubscribed) return;
      createUserSubscription();
    }
  }, [userProfile]);

  const createUserSubscription = () => {
    const API_URL = `${process.env.REACT_APP_API_URL}/v1/api/users/subscription`;

    const config = {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
        "Content-Type": "application/json",
      },
    };

    const body = {
      Id: userProfile?.Id,
    };
    axios
      .post(API_URL, body, config)
      .then((response) => {
        const subscribed = { IsSubscribed: true };
        setUserProfile(...userProfile, ...subscribed);
        //reload user profile
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (!success) {
    // If success query param is not true, show payment failure message
    //TODO
    return null;
  }
  return (
    <div className="bg-gray-100 h-screen">
      <div className="bg-white p-6  md:mx-auto">
        <svg
          viewBox="0 0 24 24"
          className="text-green-600 w-16 h-16 mx-auto my-6"
        >
          <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
          ></path>
        </svg>
        <div className="text-center">
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
            Payment Done!
          </h3>
          <p className="text-gray-600 my-2">
            Thank you for completing your secure online payment.
          </p>
          <p> Good luck with your tests! </p>
          <div className="py-10 text-center">
            <a
              href="/dashboard/writingtasks"
              className="px-12 bg-brightRed hover:bg-brightRedLight text-white font-semibold py-3"
            >
              GO BACK
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentsResponse;
