import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Cookie from "../../components/Privacy/Cookie";
import Testimonials from "../../components/Testimonials/Testimonials";
import PricingHome from "../../components/Pricing/PricingHome";
import Footer from "../../components/Footer/Footer";
import OETHero from "../../components/Hero/OETHero";
import OETFeatures from "../../components/Features/OETFeatures";
import OETCTA from "../../components/CTA/OETCTA";
import OETFAQ from "../../components/FAQ/OETFAQ";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const OETHome = () => {
  const consentGiven = localStorage.getItem("cookieConsent") === "true";

  const location = useLocation();

  // Generate the canonical URL based on the current route
  const canonicalUrl = `${window.location.origin}${location.pathname}`;

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Navbar />
      {!consentGiven && <Cookie />}
      <OETHero />
      <OETFeatures />
      <Testimonials />
      <OETCTA />
      <PricingHome />
      <OETFAQ />
      <Footer />
    </>
  );
};

export default OETHome;
