import "./App.css";
import Home from "./pages/home/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Notfound from "./components/NotFound/Notfound";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import Privacy from "./components/Privacy/Privacy";
import Terms from "./components/Terms/Terms";
import WritingTasks from "./components/WritingTasks/WritingTasks";
import Profile from "./components/Profile/Profile";
import DashboardContent from "./components/Dashboard/DashboardContent";
import DashboardSideBar from "./components/Dashboard/DashboardSideBar";
import ExamOptions from "./components/Signup/ExamOptions";
import PaymentsResponse from "./components/Payments/PaymentsResponse";
import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Payment from "./components/Payments/Payment";
import Pricing from "./components/Pricing/Pricing";
import WritingTaskIntro from "./components/WritingTasks/WritingTaskIntro";
import WritingTask from "./components/WritingTasks/WritingsTask";
import Writing1 from "./components/WritingTasks/Writing1";
import Writing2 from "./components/WritingTasks/Writing2";
import SuccessComponent from "./components/WritingTasks/SuccessComponent";
import Writing3 from "./components/WritingTasks/Writing3";
import IELTSHome from "./pages/IELTS/IELTSHome";
import OETHome from "./pages/OET/OETHome";
import PTEHome from "./pages/PTE/PTEHome";
import AboutHome from "./pages/About/AboutHome";
import ContactHome from "./pages/Contact/ContactHome";
import CareersHome from "./pages/Careers/CareersHome";
import NewsHome from "./pages/News/NewsHome";

function App() {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    // console.log(
    //   "test = " +
    //     window.location.hostname.includes(process.env.REACT_APP_PUBLIC_URL)
    // );
    const publishableKey = window.location.hostname.includes(
      process.env.REACT_APP_PUBLIC_URL
    )
      ? process.env.REACT_APP_STRIPE_PK
      : process.env.REACT_APP_STRIPE_TEST_PK;
    console.log(publishableKey);
    setStripePromise(loadStripe(publishableKey));
  }, []);

  const router = createBrowserRouter([
    { path: "/", element: <Home /> },
    {
      element: <DashboardSideBar />,
      path: "/dashboard",
      children: [
        { path: "/dashboard/home", element: <DashboardContent /> },
        { path: "/dashboard/profile", element: <Profile /> },
        { path: "/dashboard/writingtasks", element: <WritingTasks /> },
        {
          path: "/dashboard/writingtasks/success",
          element: <SuccessComponent />,
        },
        {
          path: "/dashboard/writingtasks/:id/intro",
          element: <WritingTaskIntro />,
        },
        {
          path: "/dashboard/writingtasks/writing1",
          element: <Writing1 />,
        },
        {
          path: "/dashboard/writingtasks/writing2",
          element: <Writing2 />,
        },
        {
          path: "/dashboard/writingtasks/writing3",
          element: <Writing3 />,
        },

        { path: "/dashboard/writingtasks/:id", element: <WritingTask /> },
        { path: "/dashboard/upgrade", element: <Pricing /> },

        {
          path: "/dashboard/payment",
          element: <Payment stripePromise={stripePromise} />,
        },
        {
          path: "/dashboard/completion",
          element: <PaymentsResponse stripePromise={stripePromise} />,
        },
      ],
    },

    { path: "/ielts", element: <IELTSHome /> },
    { path: "/oet", element: <OETHome /> },
    { path: "/pte", element: <PTEHome /> },
    { path: "/examselection", element: <ExamOptions /> },
    { path: "/signup", element: <Signup /> },
    { path: "/login", element: <Login /> },
    { path: "/terms", element: <Terms /> },
    { path: "/privacy", element: <Privacy /> },
    { path: "/news", element: <NewsHome /> },
    { path: "/about", element: <AboutHome /> },
    { path: "/contact", element: <ContactHome /> },
    { path: "/careers", element: <CareersHome /> },
    { path: "*", element: <Notfound /> },
  ]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
