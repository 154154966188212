import React from "react";

const OETCommCase = ({ commCase }) => {
  const { scenario, details, noteToCandidate } = commCase;

  return (
    <div className="max-w-2xl mx-auto my-8 p-6 bg-white rounded shadow-md">
      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-2">OET Writing Task</h2>
        <p className="text-gray-600">{scenario}</p>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2">Details:</h3>
        <div className="text-gray-700">
          <p>Patient Information:</p>
          <ul>
            <li>Full Name: {details.patientInformation.fullName}</li>
            <li>Age: {details.patientInformation.age}</li>
            <li>Admission Date: {details.patientInformation.admissionDate}</li>
            <li>
              Current Diagnosis: {details.patientInformation.currentDiagnosis}
            </li>
          </ul>

          <p>Change in Medical Condition:</p>
          <p>{details.changeInMedicalCondition.description}</p>

          <p>Updated Treatment Plan:</p>
          <ul>
            <li>Medication Adjustments:</li>
            <ul>
              <li>
                Name: {details.updatedTreatmentPlan.medicationAdjustments.name}
              </li>
              <li>
                Previous Dosage:{" "}
                {
                  details.updatedTreatmentPlan.medicationAdjustments
                    .previousDosage
                }
              </li>
              <li>
                New Dosage:{" "}
                {details.updatedTreatmentPlan.medicationAdjustments.newDosage}
              </li>
            </ul>
            <li>
              New Medication or Therapy:{" "}
              {details.updatedTreatmentPlan.newMedicationOrTherapy}
            </li>
            <li>
              Monitoring Details:{" "}
              {details.updatedTreatmentPlan.monitoringDetails}
            </li>
          </ul>

          {/* Add rendering for other sections based on the JSON structure */}

          <p>Rationale for Changes:</p>
          <p>{details.rationaleForChanges.justification}</p>

          <p>Follow-up and Monitoring:</p>
          <ul>
            <li>Follow-up Appointment:</li>
            <ul>
              <li>
                Time Frame:{" "}
                {details.followUpAndMonitoring.followUpAppointment.timeFrame}
              </li>
              <li>
                Monitoring Parameters:{" "}
                {
                  details.followUpAndMonitoring.followUpAppointment
                    .monitoringParameters
                }
              </li>
            </ul>
            <li>
              Urgent Symptoms: {details.followUpAndMonitoring.urgentSymptoms}
            </li>
          </ul>

          <p>Collaboration and Coordination:</p>
          <ul>
            <li>
              Importance: {details.collaborationAndCoordination.importance}
            </li>
            <li>
              Contact Details:{" "}
              {details.collaborationAndCoordination.contactDetails}
            </li>
          </ul>

          <p>Patient and Family Education:</p>
          <p>{details.patientAndFamilyEducation.information}</p>

          <p>Professional and Courteous Tone:</p>
          <p>{details.professionalAndCourteousTone.tone}</p>

          <p>Closing:</p>
          <p>{details.closing.gratitude}</p>
        </div>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2">Note to Candidate:</h3>
        <p className="text-gray-600">{noteToCandidate}</p>
      </div>
    </div>
  );
};
export default OETCommCase;
