import {
  faCircleChevronDown,
  faCircleChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const defaultFAQs = [
  {
    question: "What's the purpose of taking your writing mock tests?",
    answer:
      "Our writing mock tests recreate real exam conditions, letting you refine your skills for the IELTS, OET, and PTE exams.",
    open: false,
  },
  {
    question: "How can I access the mock tests on your platform?",
    answer:
      "Upon signing up, you'll unlock our platform where diverse writing mock tests for IELTS, OET, and PTE await. The number of tests depends on your chosen pricing plan.",
    open: false,
  },
  {
    question: "What kind of feedback can I expect for my writing assessment?",
    answer:
      "Expect comprehensive feedback within 30 minues to 4 hours of submission based on your plan, assessing your performance based on various criteria aligned with the test's evaluation standards.",
    open: false,
  },
  {
    question: "Who is this course aimed for?",
    answer:
      "Designed for individuals looking to assess and elevate their writing skills while preparing for the tests.",
    open: false,
  },
  {
    question: "Where can I view the feedback for my writing assessment?",
    answer:
      "You can view the feedback for your writing assessment on your dashboard. Detailed feedback will be provided within the specified time frame based on your chosen plan.",
    open: false,
  },
  {
    question: "Can I ask clarifications on the feedback?",
    answer:
      "Yes, you can ask for clarifications on the feedback provided. Feel free to reach out to our support team via email at support@ipassmocks.com with your specific questions, and we'll be happy to assist you.",
    open: false,
  },
  {
    question: "What is the duration of access for this online training?",
    answer:
      "You'll have access to the online training for 3 months. For extended access, email us at info@ipassmocks.com, and we'll be happy to assist.",
    open: false,
  },
  {
    question: "Does this subscription include access to video classes?",
    answer:
      "As of now, video classes are not available. If you wish to book 1-on-1 video classes, contact us at info@ipassmocks.com.",
    open: false,
  },
  {
    question:
      "Whom should I contact if I have any complaints about the service?",
    answer:
      "To address any complaints, reach out to us via email at info@ipassmocks.com.",
    open: false,
  },
  {
    question: "Tell me about your pricing plans.",
    answer:
      "We offer three distinct plans: Bronze, Silver, and Gold. Each plan comes with varying numbers of mock tests and feedback durations.",
    open: false,
  },
  {
    question: "What's included in the Bronze plan?",
    answer:
      "The Bronze plan features writing mock tests for IELTS, OET, and PTE, with detailed feedback delivered within 4 hours after completing each test.",
    open: false,
  },
  {
    question: "What's included in the Silver plan?",
    answer:
      "The Silver plan comprises writing mock tests for IELTS, OET, and PTE, accompanied by detailed feedback within 1 hour after each test.",
    open: false,
  },
  {
    question: "What's included in the Gold plan?",
    answer:
      "The Gold plan includes writing mock tests for IELTS, OET, and PTE, along with detailed feedback within 30 minutes after each test.",
    open: false,
  },
  {
    question:
      "Are the mock tests designed specifically for each exam (IELTS, OET, PTE)?",
    answer:
      "Absolutely, we've curated unique sets of writing mock tests for IELTS, OET, and PTE. You get to choose the specific test type during registration.",
    open: false,
  },
  {
    question: "Can I access the mock tests from any device?",
    answer:
      "Yes, our platform is accessible across devices—laptops, tablets, and smartphones—offering flexibility in your study routine.",
    open: false,
  },
  {
    question: "How long will I have access to the mock tests and evaluations?",
    answer:
      "The standard access duration is 3 months for all plans. For extended access, reach out to us, and we'll gladly assist you.",
    open: false,
  },
  {
    question: "Do you provide revised answers for the mock test questions?",
    answer:
      "Certainly! In addition to feedback, we offer revised answers, enabling you to learn from mistakes and enhance your writing skills.",
    open: false,
  },
  {
    question: "How do I join?",
    answer:
      "Start by creating a free account or logging in. Then, navigate to the dashboard and purchase a plan to begin your journey.",
    open: false,
  },
  {
    question: "What payment methods are available?",
    answer:
      "We accept payments through various credit card types via Stripe for secure transactions. HTTPS and SSL protocols are in place for secure browsing.",
    open: false,
  },
  // {
  //   question: "Do you accept Paypal?",
  //   answer:
  //     "Absolutely! We accept payments through Paypal, as well as credit and debit cards.",
  //   open: false,
  // },
];

const FAQ = ({ customFAQs }) => {
  const [showAll, setShowAll] = useState(false);
  const FAQs = customFAQs || defaultFAQs;

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div>
      <div>
        <div>
          <div className="mx-auto text-center px-4 mt-1 text-2xl text-indigo-900 font-semibold">
            Frequently Asked Questions
          </div>
          <dl className="mt-8 mx-auto max-w-screen-sm lg:max-w-screen-lg flex flex-col lg:flex-row lg:flex-wrap">
            {FAQs.slice(0, showAll ? FAQs.length : 5).map((FAQitem, i) => (
              <FAQsingle FAQitem={FAQitem} key={i}></FAQsingle>
            ))}
          </dl>
          {FAQs.length > 5 && (
            <div className="mx-auto text-center">
              <button
                className="mx-auto text-center px-4 mt-0 mb-2 text-md text-indigo-900 font-semibold underline cursor-pointer"
                onClick={toggleShowAll}
              >
                {showAll ? "Show Less" : "Show More"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const FAQsingle = ({ FAQitem }) => {
  const [isOpen, setIsOpen] = useState(FAQitem.open || false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div>
        <div className="select-none cursor-pointer border-2 mx-8 my-3 px-6 py-4 rounded-lg text-sm group bg-gray-100">
          <dt onClick={toggle}>
            <div className="flex justify-between">
              <div className="text-indigo-800 font-semibold">
                {FAQitem.question}
              </div>
              <div>{FAQitem.open || isOpen ? iconUp : iconDown}</div>
            </div>
          </dt>
          <dd
            className={
              !isOpen
                ? "hidden mt-2 leading-snug text-gray-700"
                : "mt-2 leading-snug text-gray-700"
            }
          >
            {FAQitem.answer}
          </dd>
        </div>
      </div>
    </>
  );
};

const iconDown = (
  <FontAwesomeIcon
    icon={faCircleChevronDown}
    className="question-chevron group-hover:bg-gray-500 h-5 block text-indigo-800 bg-gray-400 rounded-full p-1"
  ></FontAwesomeIcon>
);

const iconUp = (
  <FontAwesomeIcon
    icon={faCircleChevronUp}
    className="question-chevron group-hover:bg-gray-500 h-5 block text-indigo-800 bg-gray-400 rounded-full p-1"
  ></FontAwesomeIcon>
);
export default FAQ;
