import React, { createContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import app from "../firebase/firebase.config";
import axios from "axios";
import { SigninProvider } from "../components/Utilities/Enums";

export const AuthContext = createContext("");
const auth = getAuth(app);

const UserContext = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(false);

  const createUser = (email, password) => {
    setLoading(true);
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const signIn = (email, password) => {
    setLoading(true);
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logOut = () => {
    setLoading(true);
    return signOut(auth);
  };

  const googleProvider = new GoogleAuthProvider();
  const signInWithGoogle = async () => {
    try {
      setLoading(true);
      const res = await signInWithPopup(auth, googleProvider);
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  };
  const sendPwdResetEmail = async (email) => {
    const res = await sendPasswordResetEmail(auth, email);
    console.log(res);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // If there is a logged-in user, check if the user profile needs to be updated
        if (!userProfile || userProfile.UserId !== currentUser.uid) {
          console.log(
            "Loaded User profile " +
              userProfile?.UserId +
              "  " +
              currentUser.uid
          );
          await getUserFromMongo(currentUser);
        }
      }

      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  //TODO add user and currentUser to dependecies and check if those variables are updated and can track the async flow
  const getUserFromMongo = (u) => {
    if (!u) return;

    const API_URL = `${process.env.REACT_APP_API_URL}/v1/api/users/${u?.uid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${u.accessToken}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .get(API_URL, config)
      .then((response) => {
        setUserProfile(response?.data);
        console.log(response);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          // Handle 404 error (Not Found) here
          console.log("User not found ");
          console.log("create the new user with id - " + u?.uid);
          createUserInMongo();
        } else {
          // Handle other errors
          console.log(error);
        }
      });
  };

  const createUserInMongo = () => {
    const API_URL = `${process.env.REACT_APP_API_URL}/v1/api/users`;

    const config = {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
        "Content-Type": "application/json",
      },
    };

    const body = {
      UserId: user?.uid,
      Email: user?.email,
      FullName: user?.displayName,
      Provider: SigninProvider.Google,
      emailVerified: user?.emailVerified,
    };

    axios
      .post(API_URL, body, config)
      .then((response) => {
        console.log(userProfile);
        setUserProfile(response?.data);
        console.log("userProfile created ");
        console.log(userProfile);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const authInfo = {
    auth,
    user,
    userProfile,
    loading,
    createUser,
    signIn,
    signInWithGoogle,
    logOut,
    sendPwdResetEmail,
    setUserProfile,
  };

  return (
    <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>
  );
};

export default UserContext;
