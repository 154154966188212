import React from "react";
import { Link } from "react-router-dom";
import { PricingPlans } from "../Utilities/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

const PricingHome = () => {
  const handlePlanSelection = (value) => {
    localStorage.setItem("priceingPlan", value);
  };
  return (
    <>
      <div id="pricing">
        <div className="lg:h-auto bg-gray-900">
          <div className="max-w-7xl mx-auto p-4 sm:p-10 lg:py-10">
            <div className="max-w-5xl mx-auto text-center text-white tracking-widest pb-10 lg:pb-20">
              <p className="pb-4 text-xl text-gray-200">PRICING</p>
              <h1 className="text-3xl sm:text-4xl font-black">
                Elevate Your English with Affordability
              </h1>
              <p className="text-xl sm:text-2xl font-light text-gray-200 px-10 py-6">
                Choose from our diverse packages tailored to your needs and
                budget, blending traditional strengths like expert teachers with
                digital accessibility for flexible learning anytime, anywhere.
              </p>
            </div>

            <div className="flex flex-col  md:flex-row  justify-center items-center  gap-8 lg:gap-0 lg:mt-4">
              <div className="flex-1 w-full mb-6 bg-white rounded-xl shadow-xl lg:scale-95 transition-transform transform hover:scale-105">
                <div className="text-center p-12">
                  <p className="text-3xl lg:text-2xl xl:text-3xl pb-4">
                    Bronze
                  </p>
                  <div className="flex justify justify-center items-center">
                    <span className="font-extrabold text-5xl lg:text-4xl xl:text-6xl align-text-middle px-3">
                      <span className="text-gray-500 line-through">
                        ${PricingPlans.offerings_prices_strikethrough[0]}
                      </span>{" "}
                      ${PricingPlans.offerings_prices[0]}
                    </span>
                  </div>
                </div>
                <div className="bg-gray-100 rounded-b-xl border-t-2 border-gray-200/20 p-10">
                  <ul className="space-y-4">
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>
                      <span className="text-gray-500">
                        {PricingPlans.offerings_tests[0]}{" "}
                        {PricingPlans.offerings[0]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>
                      <span className="text-gray-500">
                        {PricingPlans.offerings[1] +
                          PricingPlans.offerings_evaluation_tf[0]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[2]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[3]}
                      </span>
                    </li>
                  </ul>
                  <Link to="/signup">
                    <button
                      type="button"
                      className="w-full text-white bg-brightRed text-lg  mt-8 p-3 rounded shadow-xl transition hover:text-white hover:bg-brightRedLight"
                      onClick={() => {
                        handlePlanSelection("bronze");
                      }}
                    >
                      Choose Plan
                    </button>
                  </Link>
                </div>
              </div>
              <div className="lg:max-w-max mb-6 relative w-full bg-white rounded-xl shadow-xl border-2 border-indigo-600 lg:scale-110 z-10 transition-transform transform hover:scale-105">
                <div className="text-center p-12">
                  <p className="text-3xl lg:text-2xl xl:text-3xl pb-4 font-semibold">
                    Silver
                  </p>
                  <div className="flex justify justify-center items-center">
                    <span className="font-extrabold text-5xl lg:text-4xl xl:text-6xl align-text-middle px-3">
                      <span className="text-gray-500 line-through">
                        ${PricingPlans.offerings_prices_strikethrough[1]}
                      </span>{" "}
                      ${PricingPlans.offerings_prices[1]}
                    </span>
                    {/* <span className="font-normal text-xl text-gray-500 inline-block align-text-middle">
                      /3 months
                    </span> */}
                  </div>
                </div>
                <div className="bg-gray-100 rounded-b-xl border-t-2 border-gray-200/20 p-10">
                  <ul className="space-y-4">
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings_tests[1]}{" "}
                        {PricingPlans.offerings[0]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[1] +
                          PricingPlans.offerings_evaluation_tf[1]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[2]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[3]}
                      </span>
                    </li>
                    {/* <li className="flex">
                        <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                        <span className="text-gray-500">{offerings[4]}</span>
                      </li> */}
                  </ul>
                  <Link to="/signup">
                    <button
                      type="button"
                      className="w-full text-center bg-brightRed text-lg text-white mt-8 p-3 rounded shadow-xl transition hover:text-white hover:bg-brightRedLight"
                      onClick={() => {
                        handlePlanSelection("silver");
                      }}
                    >
                      Choose Plan
                    </button>
                  </Link>
                </div>
                <div className="absolute rounded-full w-40 bg-brightRed text-white text-center text-sm tracking-wider px-4 py-1 -top-3 inset-x-0 mx-auto">
                  MOST POPULAR
                </div>
              </div>
              <div className="flex-1 w-full mb-6 bg-white rounded-xl shadow-xl lg:scale-95 transition-transform transform hover:scale-105">
                <div className="text-center p-12">
                  <p className="text-3xl lg:text-2xl xl:text-3xl pb-4">Gold</p>
                  <div className="flex justify justify-center items-center">
                    <span className="font-extrabold text-5xl lg:text-4xl xl:text-6xl align-text-middle px-3">
                      <span className="text-gray-500 line-through">
                        ${PricingPlans.offerings_prices_strikethrough[2]}
                      </span>{" "}
                      ${PricingPlans.offerings_prices[2]}
                    </span>
                    {/* <span className="font-normal text-xl text-gray-500 inline-block align-text-middle">
                      /3 months
                    </span> */}
                  </div>
                </div>
                <div className="bg-gray-100 rounded-b-xl border-t-2 border-gray-200/20 p-10">
                  <ul className="space-y-4">
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings_tests[2]}{" "}
                        {PricingPlans.offerings[0]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[1] +
                          PricingPlans.offerings_evaluation_tf[2]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[2]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[3]}
                      </span>
                    </li>
                  </ul>
                  <Link to="/signup">
                    <button
                      type="button"
                      className="w-full text-center text-white text-lg bg-brightRed mt-8 p-3 rounded shadow-xl transition hover:text-white hover:bg-brightRedLight"
                      onClick={() => {
                        handlePlanSelection("gold");
                      }}
                    >
                      Choose Plan
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingHome;
