import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Cookie from "../../components/Privacy/Cookie";
import Testimonials from "../../components/Testimonials/Testimonials";
import PricingHome from "../../components/Pricing/PricingHome";
import Footer from "../../components/Footer/Footer";
import PTEHero from "../../components/Hero/PTEHero";
import PTEFeatures from "../../components/Features/PTEFeatures";
import PTECTA from "../../components/CTA/PTECTA";
import PTEFAQ from "../../components/FAQ/PTEFAQ";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const PTEHome = () => {
  const consentGiven = localStorage.getItem("cookieConsent") === "true";

  const location = useLocation();

  // Generate the canonical URL based on the current route
  const canonicalUrl = `${window.location.origin}${location.pathname}`;

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Navbar />
      {!consentGiven && <Cookie />}
      <PTEHero />
      <PTEFeatures />
      <Testimonials />
      <PTECTA />
      <PricingHome />
      <PTEFAQ />
      <Footer />
    </>
  );
};

export default PTEHome;
