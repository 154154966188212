import React from "react";

const PTECTA = () => {
  return (
    <>
      {/*  CTA Section */}
      <section id="cta" className="bg-brightRed">
        {/*  Flex Container */}
        <div className="container flex flex-col items-center justify-between px-6 py-24 mx-auto space-y-12 md:py-12 md:flex-row md:space-y-0">
          {/*  Heading */}
          <h2 className="text-4xl font-bold text-center text-white md:text-4xl md:max-w-2xl lg:max-w-3xl md:text-left">
            Master PTE Writing Skills with our Expertly Crafted Mock Test
            Programs and Feedbacks!
          </h2>
          {/*  Button */}
          <div>
            <a
              href="/signup"
              className="p-3 px-6 pt-2 text-brightRed bg-white rounded-full shadow-2xl baseline hover:bg-gray-900"
            >
              Get Started
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default PTECTA;
