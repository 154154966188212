import React from "react";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Careers = () => {
  return (
    <div className="container mx-auto my-8 p-8 bg-gray-100">
      <h1 className="text-3xl font-bold mb-4">Join Our Team</h1>
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-2">Current Openings</h2>
        <ul className="list-disc list-inside">
          <li className="mb-2">
            <strong>English Tutors</strong>
            <p className="ml-4">
              Be a part of our team of dedicated English tutors and contribute
              to the success of language learners worldwide. As an English tutor
              at iPASSmocks, you'll have the opportunity to inspire and guide
              students on their language proficiency journey.
            </p>
          </li>
          <li className="mb-2">
            <strong>Customer Support Representative</strong>
            <p className="ml-4">
              Provide excellent assistance to users with inquiries.
            </p>
          </li>
          <li className="mb-2">
            <strong>Frontend Developer</strong>{" "}
            <p className="ml-4">
              Contribute to the development of our user-friendly online
              platform. Experience with React and Tailwind CSS is required.
            </p>
          </li>
          <li className="mb-2">
            <strong>Marketing Specialist</strong>
            <p className="ml-4">
              Develop and execute marketing strategies to promote our mock test
              services.
            </p>
          </li>
          {/* Add more job openings as needed */}
        </ul>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">How to Apply</h2>
        <p className="">
          If you're passionate about education and want to be part of our
          iPASSmocks team, please send your resume and cover letter to{" "}
          <span className="text-blue-500 hover:text-brightRed">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            Email: <a href="mailto:info@ipassmocks.com">info@ipassmocks.com</a>
          </span>
          .
        </p>
      </div>
    </div>
  );
};

export default Careers;
