import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../Utilities/Loader";

const Signup = () => {
  const [error, setError] = useState(null);

  const { loading, createUser, signInWithGoogle } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard/profile";

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const email = form.email.value;
    const password = form.password.value;
    const confirm = form.confirm.value;
    // if (password.lenth < 6) {
    //   setError("Password should be 6 characters or more");
    //   return;
    // }
    // if (password !== confirm) {
    //   setError("Your password did not match");
    //   return;
    // }
    // createUser(email, password)
    //   .then((result) => {
    //     const newUser = result.user;
    //     console.log(newUser);
    //     form.reset();
    //     navigate(from, { replace: true });
    //   })
    //   .catch((error) => console.error(error));
  };

  const handleGoogleSignUp = () => {
    signInWithGoogle()
      .then((result) => {
        //TODO setup an observable onAuthStateChanged
        localStorage.setItem("isNewUser", true);
        navigate(from, { replace: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="flex items-center min-h-screen p-4 bg-gray-100 lg:justify-center">
      <div className="flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1 lg:max-w-screen-md">
        <div className="p-4 py-6 text-white bg-blue-500 md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly">
          <div className="my-3 text-4xl font-bold tracking-wider text-center">
            <a href="/">
              iPASS<i>mocks</i>
            </a>
          </div>
          <p className="mt-6 font-normal text-center text-gray-300 md:mt-0">
            Elevate Your English Writing Skills with Our Online Training
            Platform.
          </p>
          <p className="flex flex-col items-center justify-center mt-10 text-center">
            <span>Trusted by students across multiple countries</span>
          </p>
          <p className="mt-6 text-sm text-center text-gray-300">
            Read our{" "}
            <a href="/terms" className="underline">
              terms
            </a>{" "}
            and{" "}
            <a href="/privacy" className="underline">
              conditions
            </a>
          </p>
        </div>
        <div className="p-5 bg-white md:flex-1">
          <h3 className="my-4 text-2xl font-semibold text-gray-700">
            Account Sign Up
          </h3>
          <form onSubmit={handleSubmit} className="flex flex-col space-y-5">
            <div className="flex flex-col space-y-4">
              <a
                onClick={handleGoogleSignUp}
                className="flex items-center justify-center px-4 py-2 space-x-2 transition-colors duration-300 border border-gray-800 rounded-md group hover:bg-gray-800 focus:outline-none"
              >
                <img
                  src="https://cdn.cdnlogo.com/logos/g/35/google-icon.svg"
                  loading="lazy"
                  width="24"
                  alt="google logo"
                />
                <span>{/* add svg icons here */}</span>
                <span className="text-sm font-medium text-gray-800 group-hover:text-white">
                  Google
                </span>
              </a>
              <a
                href="#"
                className="flex items-center justify-center px-4 py-2 space-x-2 transition-colors duration-300 border border-blue-500 rounded-md group hover:bg-blue-500 focus:outline-none"
              >
                <img
                  src="https://cdn.cdnlogo.com/logos/f/91/facebook-icon.svg"
                  loading="lazy"
                  width="24"
                  alt="fb logo"
                />
                <span>{/* add svg icons here */}</span>
                <span className="text-sm font-medium text-blue-500 group-hover:text-white">
                  Facebook
                </span>
              </a>
            </div>

            <div className="flex flex-col space-y-5">
              <span className="flex items-center justify-center space-x-2">
                <span className="h-px bg-gray-400 w-14"></span>
                <span className="font-normal text-gray-500">
                  or Sign up with
                </span>
                <span className="h-px bg-gray-400 w-14"></span>
              </span>
            </div>
            <div className="flex flex-col space-y-1">
              <label
                htmlFor="email"
                className="text-sm font-semibold text-gray-500"
              >
                Email address
              </label>
              <input
                placeholder="email"
                type="email"
                name="email"
                id="email"
                autoFocus
                className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
              />
            </div>
            <div className="flex flex-col space-y-1">
              <div className="flex items-center justify-between">
                <label
                  placeholder="password"
                  htmlFor="password"
                  className="text-sm font-semibold text-gray-500"
                >
                  Password
                </label>
              </div>
              <input
                type="password"
                id="password"
                name="password"
                className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-semibold text-gray-500">
                Confirm Password
              </label>
              <input
                className="px-4 py-2 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                type="password"
                name="confirm"
              ></input>
            </div>

            <div>
              <button
                type="submit"
                className="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-brightRed rounded-md shadow hover:bg-brightRedLight  focus:outline-none focus:ring-blue-200 focus:ring-4"
              >
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
