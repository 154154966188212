import React from "react";

const Testimonials = () => {
  return (
    <>
      {/*  Testimonials */}
      <section id="testimonials">
        {/*  Container to heading and testm blocks */}
        <div className="max-w-6xl px-5 mx-auto mt-10 text-center">
          {/*  Heading */}
          <h2 className="text-4xl font-bold text-center">
            What our students say about iPASS<i>mocks</i>?
          </h2>
          {/*  Testimonials Container */}
          <div className="flex flex-col mt-24 md:flex-row md:space-x-6">
            {/*  Testimonial 1 */}
            <div className="flex flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:w-1/3">
              <img
                src="https://ucarecdn.com/d3da1d2e-0c43-4c99-9f8b-47f9374250be/LindaR.png"
                className="w-16 -mt-14"
                alt=""
              />
              <h5 className="text-lg font-bold">Linda R</h5>
              <p className="text-sm text-darkGrayishBlue">
                "I've tried other writing courses, but this one truly stands
                out. The diverse writing challenges pushed me out of my comfort
                zone, helping me discover new facets of my writing abilities.
                The correction and revised answers helped me to improve quicker"
              </p>
            </div>

            {/*  Testimonial 2 */}
            <div className="hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3">
              <img
                src="https://ucarecdn.com/9e77e8a5-67bb-4bdf-8720-e5edfd14b68e/AmanS.png"
                className="w-16 -mt-14"
                alt=""
              />
              <h5 className="text-lg font-bold">Liz M</h5>
              <p className="text-sm text-darkGrayishBlue">
                "Exceptional OET mock tests on iPASS<i>mocks</i> have
                significantly improved my English writing skills. The
                personalized feedback, realistic exam environment, and
                comprehensive insights make it an indispensable tool for medical
                professionals aiming for success."
              </p>
            </div>

            {/*  Testimonial 3 */}
            <div className="hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3">
              <img
                src="https://ucarecdn.com/1033f853-111d-4f95-b9bd-3150f6552ab7/avatarlizr.jpeg"
                className="w-16 -mt-14"
                alt=""
              />
              <h5 className="text-lg font-bold">Aman S</h5>
              <p className="text-sm text-darkGrayishBlue">
                "I highly recommend iPASS<i>mocks</i> for IELTS preparation; the
                well-structured content and insightful feedback greatly enhanced
                my readiness for the exam. The user-friendly interface and
                realistic practice tests made the learning experience seamless
                and effective."”
              </p>
            </div>
          </div>
          {/*  Button */}
          <div className="my-10 md:my-16">
            <a
              href="/signup"
              className="p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight"
            >
              Get Started
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
