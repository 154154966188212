import { useContext, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import Spinner from "../Utilities/Loader";
import { AuthContext } from "../../contexts/UserContext";
import { useLocation } from "react-router-dom";

function Payment(props) {
  const { stripePromise } = props;
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(true);
  const { user, userProfile, setUserProfile } = useContext(AuthContext);

  const location = useLocation();
  const [selectionSummary, setSelectionSummary] = useState(location.state);

  console.log(selectionSummary?.course);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    const API_URL = `${process.env.REACT_APP_API_URL}/v1/api/payment/create-payment-intent?code=${selectionSummary?.code}`;
    localStorage.getItem("priceingPlan");

    const config = {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
        "Content-Type": "application/json",
      },
    };

    fetch(API_URL, {
      method: "POST",
      headers: config.headers,
    })
      .then((res) => res.json())
      .then(({ clientSecret }) => {
        setClientSecret(clientSecret);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <h1 className="ms-3 mt-5 max-w-md text-2xl font-bold md:text-4xl md:text-left">
        Payment Information
      </h1>
      <h3 className="text-xl md:text-2xl font-light text-black-500 px-3 py-3">
        You’re almost there!
      </h3>
      <div className="p-2 md:p-4">
        <div className="flex items-start text-xs md:text-sm text-black-400 mb-1 opacity-70">
          <span className="text-xs text-black-400">{IconTick}</span>
          <p className="me-2 md:me-6">Protected and Encrypted Payment</p>
          {IconTick}
          <p className="me-2 md:me-6">Round-the-Clock Online Assistance</p>
          {IconTick}
          <p className="me-2 md:me-6">
            Your Data Will Always Remain Confidential
          </p>
        </div>
      </div>
      <div className="p-1 md:p-2">
        <div className="bg-gray-100 rounded-lg p-3 mb-2 md:mb-4 ">
          <h2 className="md:text-lg lg:text-2xl  font-bold mb-4">
            Order Overview
          </h2>
          <div className="mb-4 flex justify-between">
            <div>
              <p className="text-lg font-semibold mb-2">Mock Test Pack</p>
              <p className="text-gray-700">
                {selectionSummary.course} Writing Test Pack{" "}
                {selectionSummary.plan}
              </p>
            </div>
            <div>
              <p className="text-lg font-semibold mb-2">Price</p>
              <p className="text-gray-700">{selectionSummary.price} USD</p>
            </div>
          </div>
          <hr className="my-4 border-gray-300" />
          <div className="flex justify-between">
            <p className="text-lg font-semibold mb-2">Total</p>
            <p className="text-gray-700">{selectionSummary.price} USD</p>
          </div>
        </div>
      </div>
      <div className="ms-3">
        {clientSecret && stripePromise && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </>
  );
}
const IconTick = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6 text-black-300"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 13l4 4L19 7"
    />
  </svg>
);
export default Payment;
