import React from "react";
import FAQ from "./FAQ";

const PTEFAQ = () => {
  const FAQs = [
    {
      question: "What is the purpose of PTE writing mock tests?",
      answer:
        "PTE writing mock tests simulate real exam conditions, allowing you to practice and assess your writing skills for the PTE exam, tailored for individuals preparing for various proficiency levels.",
      open: false,
    },
    {
      question: "How do I access the mock tests on your platform?",
      answer:
        "Upon registration, you'll gain access to our platform, where you can find a variety of PTE writing mock tests designed for individuals to practice. The number of mock tests available depends on the plan you choose during registration.",
      open: false,
    },
    {
      question:
        "Are the mock tests specifically designed for various proficiency levels?",
      answer:
        "Yes, we offer separate sets of mock tests tailored for different proficiency levels, ensuring targeted practice for individuals at various language proficiency stages. During the purchase, you can select the proficiency-specific test type.",
      open: false,
    },
    {
      question: "What feedback will I receive after taking a mock test?",
      answer:
        "Our expert evaluators provide personalized feedback, highlighting your strengths and areas for improvement in terms of language proficiency, communication, and coherence, along with a revised version of your answer.",
      open: false,
    },
    {
      question: "Is there a limit to the number of mock tests I can take?",
      answer:
        "Yes, there is a limit. The number of mock tests available depends on the plan you choose during registration.",
      open: false,
    },
    {
      question: "Can I access the mock tests from any device?",
      answer:
        "Yes, our platform is accessible from various devices, including laptops, tablets, and smartphones, ensuring flexibility in your study routine. While attempting the tests, we recommend using a laptop or desktop to mimic the real scenario.",
      open: false,
    },
    {
      question:
        "How long will I have access to the mock tests and evaluations?",
      answer:
        "The standard access duration is 3 months. If you need longer access, please contact us, and we'll assist you accordingly.",
      open: false,
    },
    {
      question: "Do you provide revised answers for the mock test questions?",
      answer:
        "Yes, in addition to feedback, we offer revised answers, allowing you to learn from mistakes and refine your writing skills specific to your language proficiency level.",
      open: false,
    },
    {
      question: "How can I contact support if I have further questions?",
      answer:
        "If you have any additional questions or need support, please feel free to email us at info@ptemocks.com, and our team will respond promptly.",
      open: false,
    },
  ];

  return <FAQ customFAQs={FAQs}></FAQ>;
};
export default PTEFAQ;
