import React, { useContext, useMemo, useState } from "react";
import Countdown from "react-countdown";
import { AuthContext } from "../../contexts/UserContext";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useNavigate, useParams } from "react-router-dom";

const tasks = [
  {
    id: "1",
    question:
      "What can make learning a new foreign language unimportant? Give your example and explanation.",
    answer: "",
  },
  {
    id: "2",
    question:
      "Do you agree or disagree with the following statement? Parents are the best teachers. Use specific reasons and examples to support your answer.",
    answer: "",
  },
  // Add more tasks as needed
];

const writingTaskInfo = {
  title: "Academic Writing Part 1",
  timeLimit: 60 * 60 * 1000, // 1 hour
  wordLimit: 150,
};

const WritingTask = () => {
  const [wordCount, setWordCount] = useState(0);
  const [answer, setAnswer] = useState(""); // Initialize answer as an empty string
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id: taskId } = useParams(); // Get the taskId from the URL

  // Use this task based on the URL or another logic in your app
  const task = tasks.find((t) => t.id === taskId);

  const handleChange = (e) => {
    setAnswer(e.target.value);
    setWordCount(e.target.value.split(/\s+/).length);
  };

  const handleClear = () => {
    confirmAlert({
      title: "Confirm",
      message:
        "Are you sure you want to clear your answer? Note: This will not reset the timer.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setAnswer("");
            setWordCount(0);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSubmit = () => {
    // Add your submit logic, e.g., save the answer to the backend
    // Here, I'm just logging the answer for demonstration
    console.log("Submitted Answer:", answer);
    // Move to the next task
    const currentTaskIndex = tasks.findIndex((t) => t.id === task.id);
    const nextTask = tasks[currentTaskIndex + 1];
    if (nextTask) {
      navigate(`/dashboard/writingtasks/${nextTask.id}`);
    } else {
      // If no more tasks, you can navigate to a different route or perform other actions
      console.log("No more tasks");
    }
  };

  const handleOnTimedOut = () => {
    console.log("Time's up");
    // TODO: show a toast or take appropriate action when the time is up
    handleSubmit(); // Submit the answer when time is up
  };

  // Random component
  const Completionist = () => <span>Your time is up.</span>;

  // Renderer callback with condition
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  return (
    <div className="my-8">
      <div className="mb-4 bg-black text-white p-2">
        <p>
          Timer:{" "}
          <Countdown
            date={Date.now() + writingTaskInfo.timeLimit}
            renderer={renderer}
            onComplete={handleOnTimedOut}
          />
        </p>
      </div>
      <div className="flex items-center mb-4 bg-gray-200 p-4">
        <div>
          <p className="mb-2 text-lg">
            <strong>{writingTaskInfo.title}</strong>
            <br />
            You should spend about {writingTaskInfo.timeLimit /
              (60 * 1000)}{" "}
            minutes on this task. Write at least {writingTaskInfo.wordLimit}{" "}
            words.
          </p>
        </div>
      </div>
      <h2 className="font-semibold mb-2">{task.question}</h2>

      <div className="my-2 flex w-full h-full md:min-h-full">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/ipmock-6bbd9.appspot.com/o/Screenshot%202023-12-21%20161147.png?alt=media&token=4c92d6bc-840b-46cf-99a8-8b17ec777431"
          alt="Writing Task Image"
          className="w-1/2 h-full inline rounded-md"
        />

        <textarea
          className="flex-grow p-2 border border-gray-300 rounded-md ml-4"
          placeholder="Type your answer here..."
          rows={25}
          value={answer}
          onChange={handleChange}
        />
      </div>
      <div className="flex items-center justify-center md:ms-36">
        <p>Word Count: {wordCount}</p>
      </div>

      <div className="flex items-center justify-center my-4">
        <button
          className="bg-brightRed text-white px-4 py-2 rounded-md hover:bg-brightRedLight focus:outline-none focus:shadow-outline mr-4"
          onClick={handleSubmit}
        >
          Submit
        </button>
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-400"
          onClick={handleClear}
        >
          Clear
        </button>
      </div>
    </div>
  );
};

export default WritingTask;
