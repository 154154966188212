import React from "react";

const Hero = () => {
  return (
    <section
      id="hero"
      className="container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row"
    >
      <div className="flex flex-col mb-8 md:mb-32 space-y-12 md:w-1/2">
        <h1 className="max-w-md text-4xl font-bold text-center md:text-4xl md:text-left">
          Elevate Your English Writing Skills with Our Online Training Platform.
        </h1>
        <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
          We take pride in our team of accomplished English educators who are
          dedicated to supporting you on your journey. Expect insightful
          guidance and constructive feedback from our experts as you navigate
          through our online English mock tests.
        </p>
        <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
          Practice your writing with our mock tests and obtain comprehensive
          feedback in less than 30 minutes.
        </p>
        <div className="flex justify-center md:justify-start">
          <a
            href="/signup"
            className="p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight"
          >
            Get Started
          </a>
        </div>
      </div>
      <div className="md:w-1/2">
        <img
          fetchpriority="high"
          src="https://ucarecdn.com/75b6c934-3fc8-4816-b7c1-014454e5ace4/mocksstudy_1_.webp"
          alt="iPASSmocks success"
          style={{ width: "100%", maxHeight: "700px", objectFit: "cover" }}
        />
      </div>
    </section>
  );
};

export default Hero;
