import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const WritingTaskIntro = () => {
  const isTestStarted = false;

  const instructions = {
    mainHeading: "IELTS Academic Writing",
    timeHeading: "Time: 1 hour",
    instructionsHeading: "INSTRUCTIONS TO CANDIDATES",
    instructionsContent:
      "Answer both parts. You can change your answers at any time during the test.",
    informationHeading: "INFORMATION FOR CANDIDATES",
    informationContent: [
      "There are two parts in this test.",
      "Part 2 contributes twice as much as Part 1 to the writing score.",
      "The test clock will show you when there are 10 minutes and 5 minutes remaining.",
    ],
  };

  const navigate = useNavigate();
  const { id } = useParams();

  const handleStartTestClick = () => {
    // Navigate to the new route when "Start Test" is clicked
    // Navigate to the URL with the question ID
    navigate(`/dashboard/writingtasks/${id}`);
  };

  return (
    <div className="container mx-auto p-4 bg-white rounded-md shadow-md">
      <div className="text-center mb-6">
        <h1 className="text-4xl font-bold">{instructions.mainHeading}</h1>
        <p className="text-lg font-semibold mt-2">{instructions.timeHeading}</p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-2">
          {instructions.instructionsHeading}
        </h2>
        <p>{instructions.instructionsContent}</p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-2">
          {instructions.informationHeading}
        </h2>
        <ul>
          {instructions.informationContent.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>

      <button
        className={`${
          isTestStarted
            ? "bg-gray-500 cursor-not-allowed"
            : "bg-brightRed text-white px-4 py-2 rounded-md hover:bg-brightRedLight focus:outline-none focus:shadow-outline"
        } text-white font-bold py-2 px-4 rounded mt-4 w-full`}
        onClick={handleStartTestClick}
        disabled={isTestStarted}
      >
        {isTestStarted ? "Test in Progress" : "Start Test"}
      </button>
    </div>
  );
};

export default WritingTaskIntro;
