// SuccessComponent.js

import React from "react";
import { Link } from "react-router-dom";

const SuccessComponent = () => {
  return (
    <div className="container mx-auto p-8">
      <h2 className="text-2xl font-bold mb-4 text-black">
        Test Submitted Successfully!
      </h2>
      <p className="text-lg mb-4">
        Congratulations! You've successfully submitted your writing task!
        Results will be ready for you to view within 2 hours. Best of luck!"
      </p>
      <Link
        to="/dashboard/writingtasks"
        className="bg-brightRed text-white px-4 py-2 rounded-md hover:bg-brightRedLight focus:outline-none focus:shadow-outline mr-4"
      >
        Go to Dashboard
      </Link>
    </div>
  );
};

export default SuccessComponent;
