import React from "react";

const OETCaseSummary = ({ caseSummary }) => {
  const {
    scenario,
    patient,
    treatment,
    investigations,
    summaryPoints,
    wordCount,
  } = caseSummary;

  return (
    <div className="max-w-2xl mx-auto p-4 bg-gray-100 rounded shadow">
      <h2 className="text-2xl font-bold mb-4">Patient Case Summary</h2>
      <p className="mb-4">{scenario}</p>

      <div className="mb-8">
        <h3 className="text-xl font-bold mb-2">Patient Information</h3>
        <p>
          <strong>Name:</strong> {patient.name}, <strong>Age:</strong>{" "}
          {patient.age}, <strong>Gender:</strong> {patient.gender}
        </p>
        <p>
          <strong>Diagnosis:</strong> {patient.diagnosis},{" "}
          <strong>Admission Source:</strong> {patient.admissionSource}
        </p>
        <p>
          <strong>Presenting Symptoms:</strong>{" "}
          {patient.presentingSymptoms.join(", ")}
        </p>
        <p>
          <strong>Vital Signs:</strong> Temp {patient.vitalSigns.temperature},
          HR {patient.vitalSigns.heartRate}, RR{" "}
          {patient.vitalSigns.respiratoryRate}, BP{" "}
          {patient.vitalSigns.bloodPressure}
        </p>
        <p>
          <strong>Medical History:</strong>{" "}
          {patient.medicalHistory.hypertension
            ? "Hypertension, on antihypertensive medication"
            : "No known medical history"}
        </p>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-bold mb-2">Treatment Details</h3>
        <p>
          <strong>Treatment Initiated:</strong> {treatment.initiated}
        </p>
        <p>
          <strong>Stabilization:</strong>{" "}
          {treatment.stabilization ? "Stabilized" : "Not stabilized"}
        </p>
        {treatment.persistentSymptoms.length > 0 && (
          <p>
            <strong>Persistent Symptoms:</strong>{" "}
            {treatment.persistentSymptoms.join(", ")}
          </p>
        )}
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-bold mb-2">Investigations</h3>
        <p>
          <strong>Ordered Tests:</strong>{" "}
          {investigations.orderedTests.join(", ")}
        </p>
        <p>
          <strong>Importance:</strong> {investigations.importance}
        </p>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-bold mb-2">Summary Points</h3>
        <ul>
          {summaryPoints.map((point, index) => (
            <li key={index} className="mb-2">
              {point}
            </li>
          ))}
        </ul>
      </div>

      <p className="text-gray-600">Word Count: {wordCount.min}</p>
    </div>
  );
};

export default OETCaseSummary;
