// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// const firebaseConfig = window.location.hostname.includes(
//   process.env.REACT_APP_PUBLIC_URL
// )
//   ?
const firebaseConfig = {
  apiKey: "AIzaSyAaaDpDHCqku53UrigVNoy4zUFPtXWw-ko",
  authDomain: "ipassmocks.com",
  projectId: "ipmock-6bbd9",
  storageBucket: "ipmock-6bbd9.appspot.com",
  messagingSenderId: "745282439633",
  appId: "1:745282439633:web:199559cb6f94c57d9e38f0",
  measurementId: "G-WDLPWETL9V",
};

// : {
//     apiKey: "AIzaSyD_oI0UNoIMuUks2dM_B2XnWCwv1a8-ei4",
//     authDomain: "elite-bird-435.firebaseapp.com",
//     databaseURL: "https://elite-bird-435.firebaseio.com",
//     projectId: "elite-bird-435",
//     storageBucket: "elite-bird-435.appspot.com",
//     messagingSenderId: "1014700025625",
//     appId: "1:1014700025625:web:7fc12acab9727e37a94788",
//     measurementId: "G-9WD9VZQSKD",
//   };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
