import React from "react";

const OETFeatures = () => {
  return (
    <>
      {/*  Features Section */}
      <section id="features">
        {/*  Flex container */}
        <div className="container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row">
          {/*  What's Different */}
          <div className="flex flex-col space-y-12 md:w-1/2">
            <h2 className="max-w-md text-4xl font-bold text-center md:text-left">
              Why Choose Our OET Writing Mock Tests?
            </h2>
            <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
              Experience a transformative difference with our OET online writing
              mock tests. We take pride in our team of accomplished English
              educators who are dedicated to supporting you on your journey.
              Expect insightful guidance and constructive feedback from our
              experts as you navigate through our online English mock tests.
            </p>
          </div>

          {/*  Numbered List */}
          <div className="flex flex-col space-y-4 md:w-1/2">
            {/*  List Item 1 */}
            <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-6 md:flex-row">
              {/*  Heading */}
              <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
                <div className="flex items-center space-x-2">
                  <div className="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                    01
                  </div>
                  <h3 className="text-base font-bold md:mb-4 md:hidden">
                    Expert Evaluation
                  </h3>
                </div>
              </div>

              <div>
                <h3 className="hidden mb-4 text-lg font-bold md:block">
                  Expert Evaluation
                </h3>
                <p className="text-darkGrayishBlue">
                  Get your writing evaluated by our team of experts and receive
                  detailed feedback on how to improve.
                </p>
              </div>
            </div>

            {/*  List Item 2 */}
            <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-6 md:flex-row">
              {/*  Heading */}
              <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
                <div className="flex items-center space-x-2">
                  <div className="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                    02
                  </div>
                  <h3 className="text-base font-bold md:mb-4 md:hidden">
                    Realistic Mock Tests
                  </h3>
                </div>
              </div>

              <div>
                <h3 className="hidden mb-4 text-lg font-bold md:block">
                  Realistic Mock Tests
                </h3>
                <p className="text-darkGrayishBlue">
                  Take our OET writing mock tests designed for all professions
                  to assess your writing skills.
                </p>
              </div>
            </div>

            {/*  List Item 3 */}
            <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-6 md:flex-row">
              {/*  Heading */}
              <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
                <div className="flex items-center space-x-2">
                  <div className="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                    03
                  </div>
                  <h3 className="text-base font-bold md:mb-4 md:hidden">
                    Detailed Feedback with Revised Answer
                  </h3>
                </div>
              </div>

              <div>
                <h3 className="hidden mb-4 text-lg font-bold md:block">
                  Detailed Feedback with Revised Answer
                </h3>
                <p className="text-darkGrayishBlue">
                  Receive comprehensive feedback on your writing, guiding you to
                  improve grammar, vocabulary, and coherence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OETFeatures;
