import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Plans, PricingPlans, TargetExams } from "../Utilities/Enums";
import { useNavigate } from "react-router";
import axios from "axios";
import { AuthContext } from "../../contexts/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

const OrderSummaryModal = ({
  selectedCourse,
  selectedPlan,
  onClose,
  onBuyNow,
}) => {
  const offerings_prices = PricingPlans.offerings_prices;
  const prices = {
    Bronze: offerings_prices[0],
    Silver: offerings_prices[1],
    Gold: offerings_prices[2],
  };

  const calculateTotal = () => {
    return prices[selectedPlan];
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-8 rounded shadow-lg z-10 max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Selection Summary</h2>

        {/* Order Lines */}
        <div className="mb-4">
          <p className="text-lg">
            <span className="font-semibold">Selected Course:</span>{" "}
            {selectedCourse.label}
          </p>
          <p className="text-lg">
            <span className="font-semibold">Selected Plan:</span> {selectedPlan}
          </p>
          <p className="text-lg">
            <span className="font-semibold">Price:</span> $
            {prices[selectedPlan]}
          </p>
        </div>

        {/* Suggestions and Tips */}
        <div className="mb-6">
          <h3 className="text-xl font-bold mb-2">Suggestions:</h3>
          <ul className="list-disc pl-6">
            <li>Make sure to practice regularly to improve your skills.</li>
            <li>
              Take advantage of the expert feedback provided in evaluations.
            </li>
          </ul>
        </div>

        {/* Total */}
        <div className="mb-6">
          <p className="text-lg font-bold">
            <span className="font-semibold">Total:</span> ${calculateTotal()}
          </p>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end">
          <button
            className="bg-brightRed text-white p-3 rounded hover:bg-brightRedLight mr-4"
            onClick={onBuyNow}
          >
            Buy Now
          </button>
          <button
            className="bg-gray-500 text-white p-3 rounded hover:bg-gray-600"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const Pricing = () => {
  const [selectedCourse, setSelectedCourse] = useState(
    TargetExams.find((course) => course.label == "OET-Nurses")
  );
  const [selectedPlan, setSelectedPlan] = useState({});
  const [showOrderSummary, setShowOrderSummary] = useState(false);

  const navigate = useNavigate();
  const courses = TargetExams;

  const { user, userProfile, setUserProfile } = useContext(AuthContext);

  const updateUserCourseAndPlan = () => {
    const API_URL = `${process.env.REACT_APP_API_URL}/v1/api/users/course-plan`;

    const config = {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
        "Content-Type": "application/json",
      },
    };

    const createArray = (n) => {
      return Array.from(
        { length: n },
        (_, index) => selectedCourse?.short + index + 1
      );
    };

    const body = {
      Id: userProfile?.Id,
      SelectedTest: selectedCourse.value,
      Plan: selectedPlan.value,
      Exams: createArray(PricingPlans.offerings_tests[selectedPlan.value - 1]),
    };
    axios
      .patch(API_URL, body, config)
      .then((response) => {
        const updatedUser = { ...userProfile, ...body };
        setUserProfile(updatedUser);
        console.log("course plan update success");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePlanSelection = (value) => {
    setSelectedPlan(Plans.find((plan) => plan.label == value));
    setShowOrderSummary(true);
  };

  const closeOrderSummary = () => {
    setShowOrderSummary(false);
  };

  const buyOrderSummary = () => {
    updateUserCourseAndPlan();
    navigate("/dashboard/payment", {
      state: {
        course: selectedCourse.label,
        plan: selectedPlan.label,
        price: PricingPlans.offerings_prices[selectedPlan.value - 1],
        code: selectedPlan.value,
      },
    });
  };

  return (
    <>
      {" "}
      {showOrderSummary && (
        <OrderSummaryModal
          selectedCourse={selectedCourse}
          selectedPlan={selectedPlan.label}
          onClose={closeOrderSummary}
          onBuyNow={buyOrderSummary}
        />
      )}
      <div id="pricing">
        <div className="lg:h-auto bg-gray-900">
          <div className="max-w-7xl mx-auto p-4 sm:p-10 lg:py-10">
            <div className="max-w-5xl mx-auto text-center text-white tracking-widest pb-3 lg:pb-5">
              <p className="pb-4 text-xl text-gray-200">PRICING</p>
              <h1 className="text-3xl sm:text-4xl font-black">
                Elevate Your English with Affordability
              </h1>
              <p className="text-xl sm:text-2xl font-light text-gray-200 px-10 py-4">
                Choose from our diverse packages tailored to your needs and
                budget, blending traditional strengths like expert teachers with
                digital accessibility for flexible learning anytime, anywhere.
              </p>
            </div>
            <div className="p-1 md:p-2 text-xl lg:text-xl xl:text-xl ">
              <div className="bg-gray-100 rounded-lg p-3  md:mb-4">
                <h2 className="md:text-lg lg:text-2xl  font-bold mb-4">
                  Course Selected
                </h2>
                <div className="mb-4 flex justify-between">
                  <div>
                    <p className="text-gray-700">
                      {selectedCourse
                        ? selectedCourse.label
                        : "Select a course"}
                    </p>
                  </div>
                  {!userProfile?.IsSubscribed ? (
                    <div>
                      <select
                        className="p-2 border rounded-md"
                        onChange={(e) => {
                          setSelectedCourse(
                            courses.find(
                              (course) => course.value == e.target.value
                            )
                          );
                        }}
                      >
                        <option value="" disabled>
                          Select a course
                        </option>
                        {courses.map((course, index) => (
                          <option key={index} value={course.value}>
                            {course.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col  md:flex-row  justify-center items-center  gap-8 lg:gap-0 lg:mt-4">
              <div className="flex-1 w-full mb-6 bg-white rounded-xl shadow-xl lg:scale-100 transition-transform transform hover:scale-105">
                <div className="text-center p-12">
                  <p className="text-3xl lg:text-2xl xl:text-3xl pb-4">
                    Bronze
                  </p>
                  <div className="flex justify justify-center items-center">
                    <span className="font-extrabold text-5xl lg:text-4xl xl:text-6xl align-text-middle px-3">
                      <span className="text-gray-500 line-through">
                        ${PricingPlans.offerings_prices_strikethrough[0]}
                      </span>{" "}
                      ${PricingPlans.offerings_prices[0]}
                    </span>
                  </div>
                </div>
                <div className="bg-gray-100 rounded-b-xl border-t-2 border-gray-200/20 p-10">
                  <ul className="space-y-4">
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>
                      <span className="text-gray-500">
                        {PricingPlans.offerings_tests[0]}{" "}
                        {PricingPlans.offerings[0]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>
                      <span className="text-gray-500">
                        {PricingPlans.offerings[1] +
                          PricingPlans.offerings_evaluation_tf[2]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[2]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[3]}
                      </span>
                    </li>
                  </ul>
                  {userProfile?.IsSubscribed && userProfile?.Plan >= 1 ? (
                    ""
                  ) : (
                    <Link>
                      <button
                        type="button"
                        className="w-full text-white bg-brightRed text-lg  mt-8 p-3 rounded shadow-xl transition hover:text-white hover:bg-brightRedLight"
                        onClick={() => {
                          handlePlanSelection("Bronze");
                        }}
                      >
                        Choose Plan
                      </button>
                    </Link>
                  )}
                </div>
              </div>
              <div className="lg:max-w-max mb-6 relative w-full bg-white rounded-xl shadow-xl border-2 border-indigo-600 lg:scale-100 z-10 transition-transform transform hover:scale-105">
                <div className="text-center p-12">
                  <p className="text-3xl lg:text-2xl xl:text-3xl pb-4 font-semibold">
                    Silver
                  </p>
                  <div className="flex justify justify-center items-center">
                    <span className="font-extrabold text-5xl lg:text-4xl xl:text-6xl align-text-middle px-3">
                      <span className="text-gray-500 line-through">
                        ${PricingPlans.offerings_prices_strikethrough[1]}
                      </span>{" "}
                      ${PricingPlans.offerings_prices[1]}
                    </span>
                  </div>
                </div>
                <div className="bg-gray-100 rounded-b-xl border-t-2 border-gray-200/20 p-10">
                  <ul className="space-y-4">
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings_tests[1]}{" "}
                        {PricingPlans.offerings[0]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[1] +
                          PricingPlans.offerings_evaluation_tf[2]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[2]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[3]}
                      </span>
                    </li>
                    {/* <li className="flex">
                        <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                        <span className="text-gray-500">{offerings[4]}</span>
                      </li> */}
                  </ul>
                  {userProfile?.IsSubscribed && userProfile?.Plan >= 2 ? (
                    ""
                  ) : (
                    <Link>
                      <button
                        type="button"
                        className="w-full text-center bg-brightRed text-lg text-white mt-8 p-3 rounded shadow-xl transition hover:text-white hover:bg-brightRedLight"
                        onClick={() => {
                          handlePlanSelection("Silver");
                        }}
                      >
                        Choose Plan
                      </button>
                    </Link>
                  )}
                </div>
                <div className="absolute rounded-full w-40 bg-brightRed text-white text-center text-sm tracking-wider px-4 py-1 -top-3 inset-x-0 mx-auto">
                  MOST POPULAR
                </div>
              </div>
              <div className="flex-1 w-full mb-6 bg-white rounded-xl shadow-xl lg:scale-100 transition-transform transform hover:scale-105">
                <div className="text-center p-12">
                  <p className="text-3xl lg:text-2xl xl:text-3xl pb-4">Gold</p>
                  <div className="flex justify justify-center items-center">
                    <span className="font-extrabold text-5xl lg:text-4xl xl:text-6xl align-text-middle px-3">
                      <span className="text-gray-500 line-through">
                        ${PricingPlans.offerings_prices_strikethrough[2]}
                      </span>{" "}
                      ${PricingPlans.offerings_prices[2]}
                    </span>
                  </div>
                </div>
                <div className="bg-gray-100 rounded-b-xl border-t-2 border-gray-200/20 p-10">
                  <ul className="space-y-4">
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings_tests[2]}{" "}
                        {PricingPlans.offerings[0]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[1] +
                          PricingPlans.offerings_evaluation_tf[2]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[2]}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="h-6 w-6 text-indigo-800 ">
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="mr-2 text-blue-700"
                        />
                      </span>

                      <span className="text-gray-500">
                        {PricingPlans.offerings[3]}
                      </span>
                    </li>
                  </ul>
                  {userProfile?.IsSubscribed && userProfile?.Plan >= 3 ? (
                    ""
                  ) : (
                    <Link>
                      <button
                        type="button"
                        className="w-full text-center text-white text-lg bg-brightRed mt-8 p-3 rounded shadow-xl transition hover:text-white hover:bg-brightRedLight"
                        onClick={() => {
                          handlePlanSelection("Gold");
                        }}
                      >
                        Choose Plan
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
