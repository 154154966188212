import React from "react";

const OETHealthcareScenario = ({ scenario, instructions }) => {
  return (
    <div className="max-w-xl mx-auto mt-8 p-6 bg-gray-100 border rounded-md shadow-md">
      <h2 className="text-2xl font-bold mb-4">OET Nurse Writing Question</h2>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Scenario:</h3>
        <p>
          You are a {scenario.role} working in a {scenario.setting}. You have
          been caring for a patient, {scenario.patient.name}, who was admitted
          with a diagnosis of {scenario.patient.diagnosis}.{" "}
          {scenario.patient.name} has completed her course of antibiotics and is
          now ready for discharge. However, she has expressed concerns about
          managing her health at home.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Instructions:</h3>
        <ul className="list-disc pl-5">
          {instructions.content.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>

      <p className="text-sm italic">
        Ensure your letter is clear, concise, and professional. Use appropriate
        medical terminology and follow the standard format for a discharge
        letter. Write between 180-200 words.
      </p>
    </div>
  );
};

export default OETHealthcareScenario;
