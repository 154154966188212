// Define and export TaskStatus object
export const TaskStatus = Object.freeze({
  NotAttempted: 0,
  InProgress: 1,
  Submitted: 2,
  EvaluationDone: 3,
  TimedOut: 4,
});

// Define and export TaskType  object
export const TaskType = Object.freeze({
  Listening: 0,
  Reading: 1,
  WritingTask: 2,
  WritingTask1: 3,
  WritingTask2: 4,
  Speaking: 5,
  CommunicationCase: 6,
  CaseSummary: 7,
  CaseNote: 8,
  HealthcareScenario: 9,
  Referral: 10,
  DischargeSummary: 11,
  DEFAULT: -1,
});

export const getStringValue = (enumObject, value) => {
  for (const key in enumObject) {
    if (enumObject[key] === value) {
      return key;
    }
  }
  return null; // Return null if the value is not found
};

export const getNumericValue = (enumObject, key) => {
  return enumObject[key] || enumObject.DEFAULT;
};

// Define and export SigninProvider object
export const SigninProvider = Object.freeze({
  Google: 1,
  Facebook: 2,
  Custom: 3,
});

// Define and export TargetExams array
export const TargetExams = [
  { label: "OET-Nurses", value: 2, text: "OETNurses", short: "ODN" },
  { label: "OET-Doctors", value: 4, text: "OETDoctors", short: "ODD" },
  { label: "IELTS-Academic", value: 6, text: "IELTSAcademic", short: "IEA" },
  { label: "IELTS-General", value: 8, text: "IELTSGeneral", short: "IEG" },
  { label: "PTE", value: 10, text: "PTE", short: "PTE" },
  { label: "TOFEL", value: 12, text: "TOFEL", short: "TOF" },
];

// Define and export plans array
export const Plans = [
  { label: "Bronze", value: 1 },
  { label: "Silver", value: 2 },
  { label: "Gold", value: 3 },
];

export const PricingPlans = {
  offerings_tests: ["6", "12", "20"],
  offerings_prices: ["20", "30", "50"],
  offerings_prices_strikethrough: ["35", "50", "85"],
  offerings_evaluation_tf: ["4 hours.", "1 hour.", "30 minutes."],
  offerings_evaluation_tf_mins: [210, 48, 18],
  offerings: [
    "practice writing tasks.",
    "Evaluations with expert feedback in ",
    "Obtain an indicative score range.",
    "3 months access.",
    "Tutor Support.",
  ],
};

export const TestStatus = Object.freeze({
  NotAttempted: 0,
  InProgress: 1,
  Submitted: 2,
  EvaluationDone: 3,
  TimedOut: 4,
});
