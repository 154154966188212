import React from "react";

const Features = () => {
  return (
    <>
      {/*  Features Section */}
      <section id="features">
        {/*  Flex container */}
        <div className="container flex flex-col px-4 mx-auto mt-2 md:mt-10 space-y-8 md:space-y-0 md:flex-row">
          {/*  What's Different */}
          <div className="flex flex-col space-y-6 md:space-y-12 md:w-1/2">
            <h2 className="max-w-md text-4xl font-bold text-center md:text-left">
              What's different about iPASS
              <i>mocks</i>?
            </h2>

            <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
              What makes iPASSmocks exceptional is our dedication to tailoring
              the learning experience. Benefit from mock tests that adapt to
              your needs, providing targeted insights and fostering a journey of
              continuous improvement.
            </p>
            <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
              Enjoy standard access for three months, and if you need more time,
              simply contact us for extended access. Your convenience is our
              priority.
            </p>
          </div>

          {/*  Numbered List */}
          <div className="flex flex-col space-y-4 md:w-1/2">
            {/*  List Item 1 */}
            <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-6 md:flex-row">
              {/*  Heading */}
              <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
                <div className="flex items-center space-x-2">
                  <div className="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                    01
                  </div>
                  <h3 className="text-base font-bold md:mb-4 md:hidden">
                    Get Immediate Feedback with band score
                  </h3>
                </div>
              </div>

              <div>
                <h3 className="hidden mb-4 text-lg font-bold md:block">
                  Get Immediate Feedback with band score
                </h3>
                <p className="text-darkGrayishBlue">
                  DISCOVER Your Position Quickly. Receive detailed feedback to
                  pinpoint specific areas for targeted improvement.
                </p>
              </div>
            </div>

            {/*  List Item 2 */}
            <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-6 md:flex-row">
              {/*  Heading */}
              <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
                <div className="flex items-center space-x-2">
                  <div className="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                    02
                  </div>
                  <h3 className="text-base font-bold md:mb-4 md:hidden">
                    Accessible Anytime, Anywhere
                  </h3>
                </div>
              </div>

              <div>
                <h3 className="hidden mb-4 text-lg font-bold md:block">
                  Accessible Anytime, Anywhere
                </h3>
                <p className="text-darkGrayishBlue">
                  Study at your own pace and convenience with our online
                  platform, ensuring flexibility to accommodate your busy
                  schedule.
                </p>
              </div>
            </div>

            {/*  List Item 3 */}
            <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-6 md:flex-row">
              {/*  Heading */}
              <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
                <div className="flex items-center space-x-2">
                  <div className="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                    03
                  </div>
                  <h3 className="text-base font-bold md:mb-4 md:hidden">
                    Comprehensive feedbacks with revised answer
                  </h3>
                </div>
              </div>

              <div>
                <h3 className="hidden mb-4 text-lg font-bold md:block">
                  Comprehensive feedbacks with revised answer
                </h3>
                <p className="text-darkGrayishBlue">
                  Evaluate your knowledge and identify strengths and areas for
                  improvement, guiding your study plan. Moreover, benefit from
                  the feature of receiving revised answers, allowing you to
                  learn from your mistakes and further refine your writing
                  skills.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
