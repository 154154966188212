import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../../contexts/UserContext";
import { ResponseContext } from "../../contexts/UserResponseContext";
import { PricingPlans, TargetExams } from "../Utilities/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../Utilities/Loader";

const WritingTasks = () => {
  const navigate = useNavigate();
  const { userProfile } = useContext(AuthContext);
  const { userResponses } = useContext(ResponseContext);

  const [exams, setExams] = useState([]);

  useEffect(() => {
    const fetchExams = async () => {
      try {
        if (!userProfile || exams.length > 0) return;

        let category = "IELTSAcademic"; // Default category
        // Uncomment the following block if you need to filter exams based on user's selected test
        if (userProfile?.SelectedTest) {
          const selectedTest = TargetExams.find(
            (x) => x.value === userProfile.SelectedTest
          );
          if (selectedTest) category = selectedTest.text;
        }

        const response = await fetch(`/${category.toLowerCase()}.json`);

        if (response.ok) {
          setExams(await response.json());
        }
      } catch (error) {
        console.error("Error fetching exam data:", error);
      }
    };
    fetchExams();
  }, [userProfile, exams, userResponses]);

  const handleAnswerClick = (exam) => {
    let targetUrl;

    switch (exam.type.toLowerCase()) {
      case "writing1":
        targetUrl = "/dashboard/writingtasks/writing1";
        break;
      case "writing2":
        targetUrl = "/dashboard/writingtasks/writing2";
        break;
      case "referral":
      case "dischargesummary":
      case "casenote":
      case "casesummary":
      case "communicationcase":
      case "healthcarescenario":
        targetUrl = "/dashboard/writingtasks/writing3";
        break;
      // If the type doesn't match any of the cases, set a default URL
      default:
        targetUrl = "/dashboard/writingtasks";
    }

    navigate(targetUrl, {
      state: {
        userResponse: userResponses.find(
          (response) => response.QId === exam.id
        ),
        exam,
      },
    });
  };

  const isQuestionUnlocked = (index) => {
    if (
      userProfile?.IsSubscribed &&
      index < PricingPlans.offerings_tests[userProfile?.Plan - 1]
    ) {
      return true;
    }
    return false;
  };

  if (userProfile) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-lg md:text-xl font-semibold mb-4">
          {" "}
          {
            TargetExams.find((x) => x.value === userProfile.SelectedTest).label
          }{" "}
          Writing Mock Tests
        </h1>
        <div className="grid grid-cols-1 gap-4 text-sm">
          {exams.map((exam, index) => (
            <div
              key={index}
              className="bg-white p-4 shadow-md rounded-md flex items-center justify-between"
            >
              <p>{exam.text}</p>
              {isQuestionUnlocked(index) ? (
                <button
                  className="bg-brightRed text-white px-4 py-2 rounded-md hover:bg-brightRedLight focus:outline-none focus:shadow-outline"
                  onClick={() => handleAnswerClick(exam)}
                >
                  {userResponses?.some((response) => response?.QId === exam?.id)
                    ? "View Feedback"
                    : "Start test"}
                </button>
              ) : (
                <Link to="/dashboard/upgrade">
                  <button className="bg-brightRed text-white px-4 py-2 rounded-md hover:bg-brightRedLight focus:outline-none focus:shadow-outline">
                    <FontAwesomeIcon icon={faLock} />
                    &nbsp; Upgrade
                  </button>
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return <Spinner />; // Return loader if userProfile is not available yet
};

export default WritingTasks;
