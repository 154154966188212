import React from "react";
import Loader from "react-js-loader";

const Spinner = ({ color }) => {
  return (
    <div>
      <Loader
        type="bubble-top"
        bgColor={"black"}
        color={"#F2A23A"}
        title={"Loading"}
        size={100}
      />
    </div>
  );
};

export default Spinner;
