import React from "react";

const renderContent = (content) => {
  if (Array.isArray(content)) {
    return (
      <ul className="list-disc ml-6">
        {content.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
    );
  } else if (typeof content === "object") {
    // Handle nested object, you might want to adjust this part
    return (
      <ul className="list-disc ml-6">
        {Object.entries(content).map(([key, value], i) => (
          <li key={i}>
            <span className="font-semibold">{key}:</span> {value}
          </li>
        ))}
      </ul>
    );
  } else {
    return <p>{content}</p>;
  }
};

const OETDischargeSummary = ({ dischargeNote }) => {
  return (
    <div className="max-w-2xl mx-auto p-6 bg-gray-100 shadow-md rounded-md">
      <h1 className="text-2xl font-bold mb-4">Discharge Summary</h1>

      {Object.keys(dischargeNote).map((section, index) => (
        <div key={index} className="mb-6">
          <h2 className="text-xl font-semibold mb-2">{section}</h2>
          {renderContent(dischargeNote[section])}
        </div>
      ))}
    </div>
  );
};

export default OETDischargeSummary;
