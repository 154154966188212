import React from "react";
import fb from "./../../img/icon-facebook.svg";
import yt from "./../../img/icon-youtube.svg";
import insta from "./../../img/icon-instagram.svg";
import logo from "./../../img/logo-white.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* Footer */}
      <footer className="bg-veryDarkBlue">
        {/* Flex Container */}
        <div className="container flex flex-col md:flex-row justify-between px-6 py-10 mx-auto space-y-8 md:space-y-0">
          {/* Logo and social links container */}
          <div className="flex flex-col items-center justify-between space-y-6 md:flex-col md:space-y-0 md:items-start">
            {/* Logo */}
            <div>
              <Link to="/">
                <img src={logo} className="h-8" alt="iPASSmocks success logo" />
              </Link>
            </div>
            {/* Social Links Container */}
            <div className="flex justify-center space-x-4">
              {/* Link 1 */}
              <a
                target="_blank"
                href="https://www.facebook.com/iPASSmocks/"
                rel="noopener noreferrer"
              >
                <img src={fb} alt="Facebook" className="h-8" />
              </a>
              {/* Link 2 */}
              {/* <a
                href="https://www.youtube.com/channel/UCCdEyoN3oOyk1OvYn5MSrEw"
                rel="noopener noreferrer"
              >
                <img src={yt} alt="YouTube" className="h-8" />
              </a> */}
              {/* Link 3 */}
              <a
                target="_blank"
                href="https://www.instagram.com/ipass.mocks/"
                rel="noopener noreferrer"
              >
                <img src={insta} alt="Instagram" className="h-8" />
              </a>
            </div>
          </div>
          {/* List Container */}
          <div className="flex justify-around space-x-16">
            <div className="flex flex-col space-y-3 text-white">
              <Link to="/" className="hover:text-brightRed">
                Home
              </Link>
              <Link to="/ielts" className="hover:text-brightRed">
                IELTS
              </Link>
              <Link to="/oet" className="hover:text-brightRed">
                OET
              </Link>
              <Link to="/pte" className="hover:text-brightRed">
                PTE
              </Link>
            </div>
            <div className="flex flex-col space-y-3 text-white">
              <Link to="/#pricing" className="hover:text-brightRed">
                Pricing
              </Link>
              <Link to="/careers" className="hover:text-brightRed">
                Careers
              </Link>
              <Link to="/about" className="hover:text-brightRed">
                About
              </Link>
              <Link to="/contact" className="hover:text-brightRed">
                Contact us
              </Link>
            </div>
          </div>

          {/* News letter Input Container - now hidden */}
          <div className="flex flex-col text-white space-y-3">
            <Link
              to="/privacy"
              target="_blank"
              className="hover:text-brightRed"
            >
              Privacy Policy
            </Link>
            <Link to="/terms" target="_blank" className="hover:text-brightRed">
              Terms of Service
            </Link>
            <Link to="/news" className="hover:text-brightRed">
              Latest News
            </Link>
            {/* Form */}
            {/* <form>
              <div className="flex space-x-3">
                <input
                  type="text"
                  className="flex-1 px-4 rounded-full focus:outline-none"
                  placeholder="Updates in your inbox"
                />
                <button className="px-6 py-2 text-white rounded-full bg-brightRed hover:bg-brightRedLight focus:outline-none">
                  Go
                </button>
              </div>
            </form> */}
            <div className="text-white text-center md:text-left">
              Copyright &copy; {new Date().getFullYear()}, All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
