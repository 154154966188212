import React from "react";

const News = () => {
  return (
    <div className="container mx-auto my-8 p-8 bg-gray-100">
      <h1 className="text-3xl font-bold mb-4">Latest News</h1>
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-2">Exciting Updates!</h2>
        <p className="">
          We are thrilled to announce new features and improvements on
          iPASSmocks! Stay tuned for more details.
        </p>
        <p className="text-sm text-gray-500">Published on: January 10, 2024</p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">Upcoming Events</h2>
        <p className="">
          Join our upcoming webinars and events to get valuable insights into
          exam preparation strategies.
        </p>
        <p className="text-sm text-gray-500">Published on: January 15, 2024</p>
      </div>
    </div>
  );
};

export default News;
