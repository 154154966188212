import React, { useState } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

const ErrorFallback = ({ error, resetErrorBoundary }) => (
  <div
    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
    role="alert"
  >
    <strong className="font-bold">Oops!</strong>
    <span className="block sm:inline">
      {" "}
      Something went wrong. Please refresh the page or try again later.
    </span>
    <button
      className="absolute top-0 right-0 px-4 py-3"
      onClick={resetErrorBoundary}
    >
      X
    </button>
  </div>
);

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  const handleOnError = (error, errorInfo) => {
    // You can log the error to an error reporting service
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
    setHasError(true);
  };

  const handleResetErrorBoundary = () => {
    setHasError(false);
  };

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={handleResetErrorBoundary}
      onError={handleOnError}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
