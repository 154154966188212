import React from "react";

const WritingEvaluation = ({
  Score,
  // TaskResponse,
  // CoherenceAndCohesion,
  // LexicalResource,
  // GrammaticalRangeAndAccuracy,
  Strengths,
  Weaknesses,
  AreasOfImprovement,
  Feedback,
  RevisedAnswer,
}) => {
  return (
    <div className="mx-auto p-8 bg-white shadow-md rounded-md my-8">
      <h2 className="text-2xl font-bold mb-4">Evaluation</h2>
      <section className="mb-6 p-4 bg-gray-100 border rounded-md">
        <h3 className="text-xl font-bold mb-4">Score: {Score}</h3>
        {/* <p className="text-lg font-semibold mb-2">
          Task Response:{" "}
          <span className="text-base font-normal">{TaskResponse.Comment}</span>
        </p>
        <p className="text-lg font-semibold mb-2">
          Coherence and Cohesion:{" "}
          <span className="text-base font-normal">
            {CoherenceAndCohesion.Comment}
          </span>
        </p>
        <p className="text-lg font-semibold mb-2">
          Lexical Resource:{" "}
          <span className="text-base font-normal">
            {LexicalResource.Comment}
          </span>
        </p>
        <p className="text-lg font-semibold">
          Grammatical Range and Accuracy:{" "}
          <span className="text-base font-normal">
            {GrammaticalRangeAndAccuracy.Comment}
          </span>
        </p> */}
      </section>
      <section className="mb-6 p-4 bg-gray-100 border rounded-md">
        <h3 className="text-xl font-bold mb-4">Strengths</h3>
        <ul>
          {Object.entries(Strengths).map(([title, description]) => (
            <li key={title} className="mb-2">
              <strong>{title}:</strong> {description}
            </li>
          ))}
        </ul>
      </section>
      <section className="mb-6 p-4 bg-gray-100 border rounded-md">
        <h3 className="text-xl font-bold mb-4">Weaknesses</h3>
        <ul>
          {Object.entries(Weaknesses).map(([title, description]) => (
            <li key={title} className="mb-2">
              <strong>{title}:</strong> {description}
            </li>
          ))}
        </ul>
      </section>
      <section className="mb-6 p-4 bg-gray-100 border rounded-md">
        <h3 className="text-xl font-bold mb-4">Areas of Improvement</h3>
        <ul>
          {Object.entries(AreasOfImprovement).map(([title, description]) => (
            <li key={title} className="mb-2">
              <strong>{title}:</strong> {description}
            </li>
          ))}
        </ul>
      </section>
      <div className="mt-2 mb-6  p-4 bg-gray-100 border rounded-md">
        <h3 className="text-xl font-bold mb-4">Revised Answer</h3>
        <p className="whitespace-pre-line">{RevisedAnswer}</p>
      </div>
      <section className="mb-6 p-4 bg-gray-100 border rounded-md">
        <span className="text-lg font-semibold">General Feedback: </span>
        <span> {Feedback} </span>
      </section>
    </div>
  );
};

export default WritingEvaluation;
