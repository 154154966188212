import React from "react";

const About = () => {
  return (
    <div className="container mx-auto my-8 p-8 bg-white">
      <h1 className="text-3xl font-bold mb-4">About iPASSmocks</h1>
      <p className="max-w-4xl text-center text-darkGrayishBlue md:text-left mb-6">
        iPASSmocks is a dedicated platform designed to elevate your English
        language skills through realistic mock tests. Whether you are preparing
        for IELTS, OET, PTE, or other English proficiency exams, our platform
        offers a comprehensive solution to assess and enhance your language
        abilities.
      </p>
      <p className="max-w-4xl text-center text-darkGrayishBlue md:text-left mb-6">
        Our mission is to provide a user-friendly and effective environment for
        learners worldwide. We understand the challenges of language testing,
        and iPASSmocks aims to be your trusted companion on your journey to
        success.
      </p>
      <p className="max-w-4xl text-center text-darkGrayishBlue md:text-left mb-6">
        What sets us apart is our commitment to providing detailed evaluations
        and constructive feedback. Our mock tests are crafted to simulate real
        exam conditions, ensuring you are well-prepared and confident on test
        day.
      </p>
      <p className="max-w-4xl text-center text-darkGrayishBlue md:text-left ">
        Join iPASSmocks today and experience a tailored approach to English
        language proficiency testing. Your success is our priority!
      </p>
    </div>
  );
};

export default About;
