import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./UserContext";
import axios from "axios";

export const ResponseContext = createContext("");

const UserResponseContext = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [userResponses, setUserResponses] = useState(null);

  const getUserResponseFromMongo = (u) => {
    if (!u) return;
    const API_URL = `${process.env.REACT_APP_API_URL}/v1/api/WritingResponse?userId=${u?.uid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${u.accessToken}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .get(API_URL, config)
      .then((response) => {
        setUserResponses(response?.data);
        console.log(response);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    //TODO Load questions for the user, now this is loading inside the writingtasks

    // Load user response
    getUserResponseFromMongo(user);
  }, [user]); // Add 'user' to the dependency array to re-run the effect when 'user' changes

  const responseInfo = { userResponses, setUserResponses };
  return (
    <ResponseContext.Provider value={responseInfo}>
      {children}
    </ResponseContext.Provider>
  );
};

export default UserResponseContext;
