import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faArrowUp,
  faCrown,
  faHome,
  faPencilAlt,
  faUser,
  faUserCheck,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../contexts/UserContext";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "./../../img/logo-white.svg";

const StickyBanner = () => {
  return (
    <div className="fixed top-0 left-0 w-full bg-yellow-500 p-2 text-center text-white">
      <p>End of Year Sale NOW!</p>
    </div>
  );
};

const DashboardSideBar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const { user, userProfile, logOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const showStickySale = false;

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const LogOut = () => {
    logOut();

    navigate(from, { replace: true });
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <div>
      {showStickySale && <StickyBanner />}
      <div
        className="bg-gray-100"
        style={{ marginTop: showStickySale ? "2rem" : "" }}
      >
        {/* Top Navigation Bar with Inverted Colors */}
        <nav className="bg-white text-black p-4 flex items-center justify-between">
          <div className="flex items-center">
            <img className="h-12" src={logo} alt="" />
            <span className="text-lg font-bold text-brightRed">iPASS</span>
            <span className="text-lg font-bold text-black">mocks</span>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            {/* <span className="text-lg">Welcome</span> */}
            <i className="fas fa-user-circle text-2xl"></i>
          </div>
        </nav>

        <div className="md:flex flex-row ">
          <div className="md:min-w-fit md:w-2/12 text-sm">
            {/* Toggle Button with Icons (only on small screens) */}
            <button
              className="md:hidden fixed top-4 right-4 p-2 bg-gray-800 text-white rounded-md"
              onClick={toggleSidebar}
            >
              {isSidebarOpen ? (
                <FontAwesomeIcon icon={faTimes} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </button>

            {/* Sidebar */}
            <aside
              className={`bg-white md:w-128 min-h-screen p-4 ${
                isSidebarOpen ? "block" : "hidden"
              } md:block transition-all duration-300 ease-in-out`}
            >
              <nav>
                <div className="flex items-center space-x-4 p-2 mb-5">
                  <img
                    className="h-12 rounded-full"
                    src={user?.photoURL ? user.photoURL : ""}
                    alt={user?.displayName}
                  />
                  <div>
                    <h4 className="font-semibold text-sm text-gray-700 capitalize font-poppins tracking-wide">
                      {user?.displayName}
                    </h4>
                    {user?.emailVerified ? (
                      <span className="text-sm tracking-wide flex items-center space-x-1">
                        <FontAwesomeIcon
                          icon={faUserCheck}
                          className="mr-2 text-blue-700"
                        />
                        <span className="text-gray-600">Verified</span>
                      </span>
                    ) : (
                      <span className="text-gray-600">Not Verified</span>
                    )}
                  </div>
                </div>
                <ul className="space-y-2">
                  {/* Sidebar Items */}
                  {/* <Link
                    className={
                      location.pathname.indexOf("home") > 0
                        ? "flex items-center justify-between p-2 hover:bg-gray-300 focus:bg-gray-300 focus:shadow-outline"
                        : "flex items-center justify-between p-2 hover:bg-gray-300"
                    }
                    to="/dashboard/home"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faHome} className="mr-2" />
                      <span>Dashboard</span>
                    </div>
                    <i className="fas fa-chevron-down text-xs"></i>
                  </Link> */}

                  <Link
                    className={
                      location.pathname.indexOf("writingtasks") > 0
                        ? "flex items-center justify-between p-2 hover:bg-gray-300 focus:bg-gray-300 focus:shadow-outline"
                        : "flex items-center justify-between p-2 hover:bg-gray-300"
                    }
                    to="/dashboard/writingtasks"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                      <span>Writing Tasks</span>
                    </div>
                    <i className="fas fa-chevron-down text-xs"></i>
                  </Link>

                  <Link
                    className={
                      location.pathname.indexOf("profile") > 0
                        ? "flex items-center justify-between p-2 hover:bg-gray-300 focus:bg-gray-300 focus:shadow-outline"
                        : "flex items-center justify-between p-2 hover:bg-gray-300"
                    }
                    to="/dashboard/profile"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faUser} className="mr-2" />
                      <span>My Profile</span>
                    </div>
                    <i className="fas fa-chevron-down text-xs"></i>
                  </Link>
                  <Link
                    className="flex items-center justify-between p-2 hover:bg-gray-300 focus:bg-gray-300 focus:shadow-outline"
                    to="/"
                  >
                    <button onClick={LogOut}>
                      <div className="flex items-center">
                        <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                        <span>Logout</span>
                      </div>
                      <i className="fas fa-chevron-down text-xs"></i>
                    </button>
                  </Link>
                </ul>
              </nav>
              {/* Upgrade Button with Crown Icon */}
              {userProfile?.IsSubscribed ? (
                ""
              ) : (
                <div className="mt-auto text-sm">
                  <button
                    className="w-full p-2 mt-20 bg-brightRed text-white rounded-md hover:bg-brightRedLight"
                    onClick={() => setSidebarOpen(false)}
                    //onClick={handleUpgrade}
                  >
                    <Link to="/dashboard/upgrade">
                      <FontAwesomeIcon icon={faCrown} className="mr-2" />
                      Upgrade
                    </Link>
                  </button>
                </div>
              )}
            </aside>
          </div>
          <div className="md:w-10/12">
            <div className="p-0 text-gray-500">
              <Outlet></Outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSideBar;
