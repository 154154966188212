import React, { useContext, useEffect, useState } from "react";
import Countdown from "react-countdown";
import { AuthContext } from "../../contexts/UserContext";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ToastSuccess from "../Utilities/ToastSuccess";
import {
  PricingPlans,
  TaskStatus,
  TaskType,
  TestStatus,
} from "../Utilities/Enums";
import WritingEvaluation from "../WritingEvaluation/WritingEvaluation";
import { ResponseContext } from "../../contexts/UserResponseContext";

const writingTaskInfo = {
  title: "Academic Writing Part 2",
  timeLimit: 40 * 60 * 1000, // 40 minutes
  wordLimit: 250,
};

const Writing2 = () => {
  const [wordCount, setWordCount] = useState(0);
  const [answer, setAnswer] = useState("");
  const [timedOut, setTimedOut] = useState(false);
  const [success, setSuccess] = useState(false);
  const [startTime, setStartTime] = useState(Date.now());
  const [timerEndDate, setTimerEndDate] = useState(
    Date.now() + writingTaskInfo.timeLimit
  );
  const { user, userProfile } = useContext(AuthContext);
  const { userResponses, setUserResponses } = useContext(ResponseContext);

  const navigate = useNavigate();
  const location = useLocation();
  const IsWritingTask1 = location.pathname?.includes("writing1");
  const userResponse = location.state?.userResponse;
  const task = location.state?.exam;

  useEffect(() => {
    setStartTime(Date.now());
  }, []); // Set the start time when the component mounts

  const handleChange = (e) => {
    setAnswer(e.target.value);
    setWordCount(e.target.value.split(/\s+/).length);
  };

  const handleOnTimedOut = () => {
    console.log("Time's up");
    setTimedOut(true);
    handleSubmit(); // Submit the answer when time is up
  };

  const handleSubmit = () => {
    confirmAlert({
      title: "",
      message:
        "Are you sure you want to submit your answer? Note: You cannot change the answer once you submit it.",
      buttons: [
        {
          label: "Yes",
          onClick: () => createWritingResponse(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const createWritingResponse = () => {
    const API_URL = `${process.env.REACT_APP_API_URL}/v1/api/writingresponse`;
    const endTime = Date.now();
    const timeTaken = parseInt((endTime - startTime) / 1000);

    const config = {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
        "Content-Type": "application/json",
      },
    };

    const body = {
      UId: user?.uid,
      Status: TaskStatus.Submitted,
      TT: timeTaken,
      TO: timedOut,
      QId: task?.id,
      Question: task?.question,
      UA: answer,
      SelectedTest: userProfile?.SelectedTest,
      TaskType: IsWritingTask1 ? TaskType.WritingTask1 : TaskType.WritingTask2,
    };

    axios
      .post(API_URL, body, config)
      .then((response) => {
        setSuccess(true);
        console.log(response);
        navigate("/dashboard/writingtasks/success");
        setUserResponses([...userResponses, response.data]);
      })
      .catch((e) => console.log(e));
  };

  // Random component
  const Completionist = () => <span>Your time is up.</span>;

  // Renderer callback with condition
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  const exampleFeedback = userResponse?.DE;

  const IsReadyToDisplay = () => {
    const timeDelay =
      PricingPlans.offerings_evaluation_tf_mins[userProfile?.Plan - 1];
    const timeDifference = Date.now() - new Date(userResponse?.UD);
    const isTimeDifferenceGreaterThan90Minutes =
      timeDifference > timeDelay * 60 * 1000;
    return (
      exampleFeedback?.Score !== null && isTimeDifferenceGreaterThan90Minutes
    );
  };

  const IsReadOnlyMode = () =>
    exampleFeedback?.Score ||
    success ||
    userResponse?.Status == TestStatus?.Submitted;

  return (
    <>
      <ToastSuccess
        message="Answer sent for evaluation successfully."
        show={success}
      />
      <div className="my-8">
        <div className="mb-4 bg-black text-white p-2">
          {!(exampleFeedback?.Score || success) && (
            <p>
              Timer:{" "}
              <Countdown
                date={timerEndDate}
                renderer={renderer}
                onComplete={handleOnTimedOut}
              />
            </p>
          )}
        </div>
        <div className="flex items-center mb-4 bg-gray-200 p-4">
          <div>
            <p className="mb-2 text-lg">
              <strong>{writingTaskInfo.title}</strong>
              <br />
              You should spend about {writingTaskInfo.timeLimit /
                (60 * 1000)}{" "}
              minutes on this task. Write at least {writingTaskInfo.wordLimit}{" "}
              words.
            </p>
          </div>
        </div>
        <h2 className="font-semibold mb-2">{task?.question}</h2>

        <div className="my-2 flex w-full h-full md:min-h-full">
          <textarea
            className="flex-grow p-2 border border-gray-300 rounded-md ml-4"
            placeholder="Type your answer here..."
            rows={25}
            spellCheck="false"
            value={IsReadOnlyMode() ? userResponse?.UA : answer}
            onChange={handleChange}
            disabled={IsReadOnlyMode()}
          />
        </div>
        <div className="flex items-center justify-center">
          <div>
            <p>
              {IsReadOnlyMode()
                ? "Word Count: " + userResponse?.UA?.split(/\s+/).length
                : "Word Count: " + wordCount}
            </p>
          </div>
          <div className="ms-16 md:ms-64">
            {IsReadOnlyMode() ? (
              <button
                className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-400"
                disabled
              >
                Submit
              </button>
            ) : (
              <button
                className="bg-brightRed text-white px-4 py-2 rounded-md hover:bg-brightRedLight focus:outline-none focus:shadow-outline mr-4"
                onClick={handleSubmit}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
      {IsReadOnlyMode() && !IsReadyToDisplay() && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4">
          <p className="text-yellow-700">
            Your submission is currently undergoing evaluation. We kindly
            request your patience and encourage you to return after some time
            for the results. Thank you for your understanding. After submission,
            the results will be available approximately within{" "}
            {PricingPlans.offerings_evaluation_tf[userProfile?.Plan - 1]}
          </p>
        </div>
      )}
      {exampleFeedback && IsReadyToDisplay() && (
        <WritingEvaluation {...exampleFeedback} />
      )}
    </>
  );
};

export default Writing2;
