import React from "react";

const PTEHero = () => {
  return (
    <section
      id="hero"
      className="container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row"
    >
      <div className="flex flex-col mb-8 md:mb-32 space-y-12 md:w-1/2">
        <h1 className="max-w-md text-4xl font-bold text-center md:text-4xl md:text-left">
          Online PTE writing tests preparation
        </h1>
        <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
          Tailored for comprehensive PTE test preparation, our mock tests
          provide a personalized experience. Receive expert evaluations,
          detailed feedback, and revised answers, transforming your PTE
          preparation into a successful endeavor! Boost your performance and
          approach the PTE test with confidence.
        </p>
        <div className="flex justify-center md:justify-start">
          <a
            href="/signup"
            className="p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight"
          >
            Get Started
          </a>
        </div>
      </div>
      <div className="md:w-1/2">
        <img
          src="https://ucarecdn.com/1787ba77-98b8-4c1e-9581-aa5398a85b47/studentdesk.jpg"
          alt="iPASSmocks success"
          style={{ width: "100%", maxHeight: "700px", objectFit: "cover" }}
        />
      </div>
    </section>
  );
};

export default PTEHero;
