import React from "react";

const OETCaseNote = ({ caseNote }) => {
  const { task, scenario, instructions, wordLimit, noteToCandidates } =
    caseNote;

  return (
    <div className="container mx-auto p-6">
      <div className="mb-4">
        <p className="font-bold">Task:</p>
        <p>{task}</p>
      </div>
      <div className="mb-4">
        <p className="font-bold">Scenario:</p>
        <p>{scenario}</p>
      </div>
      <div className="mb-4">
        <p className="font-bold">Instructions:</p>
        <ul>
          {instructions.map((instruction, index) => (
            <li key={index}>{instruction}</li>
          ))}
        </ul>
      </div>
      <div className="mb-4">
        <p className="font-bold">Word Limit:</p>
        <p>{wordLimit}</p>
      </div>
      <div>
        <p className="font-bold">Note to Candidates:</p>
        <p>{noteToCandidates}</p>
      </div>
    </div>
  );
};

export default OETCaseNote;
