import React from "react";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Contact = () => {
  return (
    <div className="container mx-auto my-8 p-8 bg-white">
      <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
      <p className="max-w-4xl text-center md:text-left mb-6">
        If you have any questions, concerns, or need support, feel free to reach
        out to us. We are here to assist you on your journey to success.
      </p>

      <div className="flex items-center mb-4">
        <p className="max-w-4xl text-center md:text-left">
          <span className="text-blue-500 hover:text-brightRed">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            Email: <a href="mailto:info@ipassmocks.com">info@ipassmocks.com</a>
          </span>
        </p>
      </div>
      <p className="max-w-4xl text-center md:text-left">
        We value your feedback and are dedicated to ensuring you have a positive
        experience with iPASSmocks.
      </p>
    </div>
  );
};

export default Contact;
